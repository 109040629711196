/* Import Plus Jakarta Sans font */
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
}

.aboutContainer {
  min-height: 100vh;
  background: var(--background);
  color: var(--foreground);
  position: relative;
  overflow: hidden;
  font-family: "Plus Jakarta Sans", sans-serif;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.content {
  height: 100vh;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
  position: relative;
  scroll-behavior: smooth;
}

.content::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.backButton {
  position: fixed;
  top: 2rem;
  left: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--foreground);
  background: transparent;
  border: none;
  z-index: 100;
  text-decoration: none;
}

.backButton svg {
  transition: transform 0.3s ease;
}

.backButton:hover svg {
  transform: translateX(-4px);
}

.header {
  min-height: 100vh;
  padding: 6rem var(--content-spacing);
  scroll-snap-align: start;
  scroll-snap-stop: always;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}

.titleWrapper {
  padding: 1rem 0;
  margin-bottom: 2rem;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title {
  font-size: clamp(3.5rem, 12vw, 7.5rem);
  font-weight: 200;
  line-height: 1.2;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: -0.02em;
  padding: 1rem 0;
  margin: 0;
  word-spacing: 0.1em;
  display: flex;
  flex-direction: column;
  gap: 0.25em;
}

.title span {
  display: block;
  transform: translateY(0.5em);
}

.subtitle {
  font-size: clamp(1.25rem, 2.5vw, 1.75rem);
  color: var(--muted);
  max-width: 800px;
  line-height: 1.5;
  font-weight: 300;
  letter-spacing: -0.02em;
  margin: 0 auto;
  padding: 0 2rem;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0.1s forwards;
}

.section {
  min-height: 100vh;
  padding: 8rem var(--content-spacing);
  scroll-snap-align: start;
  scroll-snap-stop: always;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  will-change: transform;
}

.sectionTitle {
  font-size: 2.5rem;
  font-weight: 200;
  margin-bottom: 3rem;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: -0.02em;
  opacity: 0;
  transform: translateY(20px);
}

.valueGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-top: 6rem;
  position: relative;
}

.valueCard {
  position: relative;
  padding: 3.5rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  transition: transform 0.4s ease, border-color 0.4s ease;
  opacity: 0;
  transform: translateY(30px);
  will-change: transform, opacity;
}

.valueCard::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, var(--accent-muted) 0%, transparent 100%);
  opacity: 0;
  transition: opacity 0.4s ease;
}

.valueCard:hover {
  transform: translateY(-8px);
  border-color: var(--accent);
}

.valueCard:hover::before {
  opacity: 0.05;
}

.valueNumber {
  font-size: 5rem;
  font-weight: 200;
  line-height: 1;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  opacity: 0.15;
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  letter-spacing: -0.06em;
}

.valueTitle {
  font-size: 1.75rem;
  font-weight: 300;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: -0.02em;
  line-height: 1.2;
  position: relative;
}

.valueDescription {
  font-size: 1.125rem;
  color: var(--muted);
  line-height: 1.7;
  letter-spacing: -0.01em;
  font-weight: 300;
}

.valueMetric {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.9375rem;
  color: var(--accent);
  font-weight: 400;
  letter-spacing: -0.01em;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.valueCard:hover .valueMetric {
  opacity: 1;
}

.valueMetric svg {
  width: 1.125rem;
  height: 1.125rem;
  transition: transform 0.3s ease;
}

.valueCard:hover .valueMetric svg {
  transform: translateX(4px);
}

.impactSection {
  background: transparent;
  overflow: visible;
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: 100% !important;
}

.impactSection .sectionTitle {
  padding-left: var(--content-spacing);
  padding-right: var(--content-spacing);
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.impactCarouselContainer {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  overflow: hidden;
  position: relative;
}

.impactCarousel {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  width: max-content;
  animation: scroll 60s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.impactCard {
  position: relative;
  padding: 3rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  transition: transform 0.4s ease, border-color 0.4s ease;
  min-width: 350px;
  max-width: 350px;
}

.impactCard:hover {
  transform: translateY(-8px);
  border-color: var(--accent);
}

.impactIcon {
  color: var(--accent);
  opacity: 0.8;
  margin-bottom: 1rem;
}

.impactMetric {
  font-size: 4rem;
  font-weight: 200;
  line-height: 1;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: -0.04em;
}

.impactLabel {
  font-size: 1.5rem;
  color: var(--foreground);
  font-weight: 300;
  letter-spacing: -0.02em;
  line-height: 1.2;
}

.impactDescription {
  font-size: 1.125rem;
  color: var(--muted);
  line-height: 1.6;
  font-weight: 300;
  letter-spacing: -0.01em;
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.inView {
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

@media (max-width: 1200px) {
  .valueGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
  }

  .impactCard {
    min-width: 300px;
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .header,
  .section {
    padding: 6rem 1.5rem;
  }

  .valueGrid {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 4rem;
  }

  .valueCard,
  .impactCard {
    padding: 2.5rem;
  }

  .impactCard {
    min-width: 280px;
    max-width: 280px;
  }
}

.statsSection {
  background: linear-gradient(
    135deg,
    var(--background) 0%,
    var(--background-light) 100%
  );
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 4rem;
}

.statCard {
  text-align: center;
  padding: 2rem;
  opacity: 0;
  transform: translateY(20px);
}

.statNumber {
  font-size: 3.5rem;
  font-weight: 200;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 1rem;
}

.statLabel {
  font-size: 1.125rem;
  color: var(--muted);
  font-weight: 300;
}

.teamSection {
  background: linear-gradient(
    135deg,
    var(--background-light) 0%,
    var(--background) 100%
  );
}

.teamGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-top: 4rem;
}

.teamMember {
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
}

.memberImage {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 1.5rem;
  background: var(--accent-muted);
  opacity: 0.1;
}

.memberName {
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--foreground);
  margin-bottom: 0.5rem;
}

.memberRole {
  font-size: 1rem;
  color: var(--muted);
  font-weight: 300;
}

.delay1 {
  animation-delay: 0.1s;
}
.delay2 {
  animation-delay: 0.2s;
}
.delay3 {
  animation-delay: 0.3s;
}
.delay4 {
  animation-delay: 0.4s;
}

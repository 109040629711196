/*! ---------------------------------------- Font Imports ---------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
}

.aboutContainer {
  min-height: 100vh;
  background: var(--background);
  color: var(--foreground);
  position: relative;
  overflow-x: hidden;
  font-family: "Plus Jakarta Sans", sans-serif;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.content {
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  height: 100vh;
  scroll-behavior: smooth;
}

.content::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.navbar {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background: rgba(var(--background-rgb), 0.4);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.75rem;
  border-radius: 16px;
  width: 90%;
  max-width: 1100px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
}

.navContent {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.1rem;
  text-decoration: none;
  transition: opacity 0.2s ease;
  padding: 0.25rem;
}

.logoIcon {
  height: 2rem;
  width: auto;
  object-fit: contain;
  margin-right: -0.1rem;
}

.logo span {
  background: linear-gradient(135deg, #fff 0%, #818cf8 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: -0.01em;
  padding-top: 3px;
  font-size: 1.5rem;
  font-weight: 600;
}

.logo:hover {
  opacity: 0.9;
}

.navLinks {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  background: rgba(var(--background-rgb), 0.3);
  padding: 0.5rem 1rem;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.navLink {
  color: var(--muted);
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  transition: color 0.2s ease;
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
  background: none;
  border: none;
  cursor: pointer;
}

.navLink:hover {
  color: var(--foreground);
  background: rgba(var(--background-rgb), 0.8);
}

.loginLink {
  padding: 0.5rem 1.25rem;
  border-radius: 8px;
  background: rgba(59, 130, 246, 0.1);
  color: #3b82f6;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  border: 1px solid rgba(59, 130, 246, 0.2);
}

.loginLink:hover {
  background: rgba(59, 130, 246, 0.15);
  box-shadow: 0 0 0 1px rgba(59, 130, 246, 0.4);
  color: #3b82f6;
}

.header {
  min-height: 100vh;
  padding: 6rem var(--content-spacing);
  scroll-snap-align: start;
  scroll-snap-stop: always;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}

.titleWrapper {
  flex: 1;
  max-width: 600px;
  padding: 1rem 0;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.gradientText {
  background: linear-gradient(135deg, #fff 0%, #818cf8 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.title {
  font-size: clamp(2.5rem, 8vw, 4.5rem);
  font-weight: 200;
  line-height: 1.2;
  letter-spacing: -0.02em;
  margin: 0;
  composes: gradientText;
}
.title1 {
  font-size: clamp(2rem, 6vw, 2.8rem);

  font-weight: 200;
  line-height: 1.2;
  letter-spacing: -0.02em;
  margin: 0;
}

.subtitle {
  font-size: 1.125rem;
  color: var(--muted);
  line-height: 1.6;
  font-weight: 300;
  letter-spacing: -0.01em;
  margin: 0;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0.1s forwards;
  text-align: left;
}

.heroAnimation {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0.2s forwards;
}

.heroButtons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.planAction {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.875rem 1.5rem;
  margin-top: 1.5rem;
  font-size: 0.9375rem;
  font-weight: 500;
  color: var(--foreground);
  background: transparent;
  border: 1px solid var(--border);
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  text-decoration: none;
}

.planAction:hover {
  background: var(--accent);
  border-color: var(--accent);
  color: var(--background);
}

.highlighted .planAction {
  background: var(--accent);
  border-color: var(--accent);
  color: var(--background);
}

.highlighted .planAction:hover {
  opacity: 0.9;
}

.primaryButton {
  padding: 0.875rem 1.75rem;
  background: rgba(59, 130, 246, 0.1);
  color: #3b82f6;
  border: 1px solid rgba(59, 130, 246, 0.2);
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.95rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.primaryButton:hover {
  background: rgba(59, 130, 246, 0.15);
  box-shadow: 0 0 0 1px rgba(59, 130, 246, 0.4);
  color: #3b82f6;
}

.primaryButton svg {
  transition: transform 0.2s ease;
  margin-left: 2px;
}

.primaryButton:hover svg {
  transform: translateX(4px);
}

.secondaryButton {
  padding: 0.875rem 1.75rem;
  background: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  transition: background-color 0.2s ease;
  font-weight: 500;
  font-size: 0.95rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.secondaryButton:hover {
  background: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 1);
}

.secondaryButton svg {
  transition: transform 0.2s ease;
  margin-left: 2px;
}

.secondaryButton:hover svg {
  transform: translateX(4px);
}

.section {
  min-height: 100vh;
  padding: 8rem var(--content-spacing);
  scroll-snap-align: start;
  scroll-snap-stop: always;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  will-change: transform;
}

.sectionTitle {
  font-size: 3rem;
  font-weight: 200;
  margin-bottom: 4rem;
  letter-spacing: -0.02em;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
  text-align: left;
  composes: gradientText;
}

.sectionSubtitle {
  font-size: 1.25rem;
  color: var(--muted);
  text-align: left;
  max-width: 800px;
  margin: 1rem 0 3rem;
  line-height: 1.6;
}

.valueGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-top: 6rem;
  position: relative;
}

.valueCard {
  position: relative;
  padding: 3.3rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  transition: transform 0.4s ease, border-color 0.4s ease;
  opacity: 0;
  transform: translateY(30px);
  will-change: transform, opacity;
}

.valueCard::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, var(--accent-muted) 0%, transparent 100%);
  opacity: 0;
  transition: opacity 0.4s ease;
}

.valueCard:hover {
  transform: translateY(-8px);
  border-color: var(--accent);
}

.valueCard:hover::before {
  opacity: 0.05;
}

.valueIcon {
  font-size: 2rem;
  color: var(--accent);
}

.valueTitle {
  font-size: 1.75rem;
  font-weight: 200;
  letter-spacing: -0.02em;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  composes: gradientText;
}

.valueDescription {
  font-size: 1.125rem;
  line-height: 1.6;
  color: var(--muted);
}

.featuresList {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.featuresList li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--muted);
  font-size: 0.95rem;
  line-height: 1.5;
}

.featuresList li::before {
  content: "•";
  color: var(--accent);
}

.impactSection {
  background: transparent;
  overflow: visible;
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: 100% !important;
}

.impactSection .sectionTitle {
  padding-left: var(--content-spacing);
  padding-right: var(--content-spacing);
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.impactCarouselContainer {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  overflow: hidden;
  position: relative;
}

.impactCarousel {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  width: max-content;
  animation: scroll 60s linear infinite;
}

.impactCard {
  position: relative;
  padding: 3rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  transition: transform 0.4s ease, border-color 0.4s ease;
  min-width: 350px;
  max-width: 350px;
}

.impactCard:hover {
  transform: translateY(-8px);
  border-color: var(--accent);
}

.impactCard {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  padding: 2.5rem;
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.impactCard:hover {
  transform: translateY(-4px);
  border-color: var(--accent);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.testimonialQuote {
  font-size: 1.125rem;
  color: var(--muted);
  line-height: 1.6;
  font-weight: 300;
  letter-spacing: -0.01em;
}

.testimonialAuthor {
  font-size: 1.25rem;
  color: var(--foreground);
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.2;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.testimonialTitle {
  font-size: 1rem;
  color: var(--muted);
  font-weight: 300;
  letter-spacing: -0.01em;
}

.socialProofSection {
  background: transparent;
  overflow: visible;
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: 100% !important;
  position: relative;
}

.socialProofContent {
  padding-left: var(--content-spacing);
  padding-right: var(--content-spacing);
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.testimonialCarouselContainer {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  overflow: hidden;
  position: relative;
}

.testimonialCarousel {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  width: max-content;
  animation: scroll 60s linear infinite;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

.testimonialCard {
  position: relative;
  padding: 3rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  transition: transform 0.4s ease, border-color 0.4s ease;
  min-width: 350px;
  max-width: 350px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

.testimonialCard:hover {
  transform: translateY(-8px);
  border-color: var(--accent);
}

.testimonialText {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--foreground-muted);
  margin-bottom: 2rem;
  font-style: italic;
  flex-grow: 1;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.testimonialText::before {
  content: '"';
  color: var(--accent);
  font-size: 2rem;
  margin-right: 0.5rem;
  line-height: 0;
  vertical-align: middle;
}

.testimonialAuthor {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--card-border);
  margin-top: auto;
}

.authorName {
  font-weight: 600;
  color: var(--foreground);
  font-size: 1.1rem;
}

.authorTitle {
  font-size: 0.875rem;
  color: var(--accent);
  line-height: 1.4;
}

.universitiesTrack {
  display: flex;
  gap: 4rem;
  animation: scrollRight 35s linear infinite;
  margin: 3rem 0;
  width: fit-content;
  align-items: center;
  padding: 1rem 0;
  position: relative;
  z-index: 1;
}

.universityName {
  font-size: 1.1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  transition: all 0.3s ease;
  flex-shrink: 0;
  cursor: default;
  letter-spacing: 0.02em;
}

.universityName:hover {
  color: var(--accent);
  transform: translateY(-2px);
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 2rem));
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(calc(-100% - 4rem));
  }
  100% {
    transform: translateX(0);
  }
}

.socialProofSection::before,
.socialProofSection::after {
  content: "";
  position: absolute;
  top: 0;
  width: 20%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.socialProofSection::before {
  left: 0;
  background: linear-gradient(to right, var(--background), transparent);
}

.socialProofSection::after {
  right: 0;
  background: linear-gradient(to left, var(--background), transparent);
}

.demoSection {
  display: flex;
  align-items: center;
  gap: 4rem;
  margin-top: 2rem;
  min-height: 600px;
  position: relative;
}

.demoContent {
  flex: 1;
  max-width: 500px;
}

.demoContent .sectionTitle,
.titleWrapper .title {
  text-align: left;
}

.demoPreview {
  flex: 1;
  min-width: 600px;
  width: 600px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0.2s forwards;
  position: relative;
}

.demoPreview > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1200px) {
  .valueGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    padding: 4rem var(--content-spacing);
    text-align: center;
    gap: 2rem;
  }

  .titleWrapper {
    align-items: center;
    text-align: center;
  }

  .heroButtons {
    flex-direction: column;
    width: 100%;
  }

  .primaryButton,
  .secondaryButton {
    width: 100%;
    justify-content: center;
  }

  .heroAnimation {
    width: 100%;
  }

  .valueGrid {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 3rem;
  }

  .valueCard {
    padding: 2rem;
  }

  .demoSection {
    flex-direction: column;
    gap: 2rem;
    min-height: auto;
  }

  .demoContent {
    text-align: center;
    max-width: 100%;
  }

  .demoPreview {
    min-width: 100%;
    width: 100%;
    height: 500px;
  }

  .demoPreview > * {
    position: relative;
  }

  .demoContent .sectionTitle {
    text-align: center;
  }

  .sectionTitle,
  .sectionSubtitle,
  .subtitle {
    text-align: center;
  }

  .sectionSubtitle,
  .subtitle {
    margin-left: auto;
    margin-right: auto;
  }

  .navbar {
    width: calc(100% - 2rem);
    top: 0.5rem;
  }

  .navContent {
    padding: 0 0.5rem;
  }

  .navLinks {
    display: none;
  }

  .loginLink {
    margin-left: auto;
  }

  .testimonialCard {
    min-width: 280px;
    max-width: 280px;
    padding: 2.5rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .content {
    scroll-snap-type: none;
  }

  .impactCarousel {
    animation: none;
  }

  .testimonialCarousel {
    animation: none;
  }

  * {
    animation: none !important;
    transition: none !important;
  }
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.inView {
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

.faqGrid {
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.faqCard {
  position: relative;
  padding: 2.5rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 2px;
  overflow: hidden;
  transition: transform 0.4s ease, border-color 0.4s ease;
}

.faqCard::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, var(--accent-muted) 0%, transparent 100%);
  opacity: 0;
  transition: opacity 0.4s ease;
}

.faqCard:hover {
  transform: translateY(-8px);
  border-color: var(--accent);
}

.faqCard:hover::before {
  opacity: 0.05;
}

.faqQuestion {
  font-size: 1.5rem;
  font-weight: 200;
  letter-spacing: -0.02em;
  margin-bottom: 1rem;
  composes: gradientText;
}

.faqAnswer {
  font-size: 1.125rem;
  line-height: 1.6;
  color: var(--muted);
}

@media (max-width: 768px) {
  .faqGrid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

/* For the Meet Lumi button specifically */
.heroButtons .primaryButton svg {
  transition: transform 0.2s ease;
  margin-left: 2px;
}

.heroButtons .primaryButton:hover svg {
  transform: translateX(4px);
}

.statValue {
  font-size: 3.5rem;
  font-weight: 200;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.statLabel {
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--foreground-muted);
  line-height: 1.4;
}

.impactCard {
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  padding: 2.5rem;
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.impactCard:hover {
  transform: translateY(-4px);
  border-color: var(--accent);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.heroSection {
  min-height: 100vh;
  padding: 6rem 0;
  background: var(--background);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.heroContent {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 0 2rem;
  position: relative;
  z-index: 1;
}

.heroTitle {
  font-size: clamp(3rem, 5vw, 4.5rem);
  font-weight: 200;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

.heroDescription {
  font-size: 1.25rem;
  line-height: 1.6;
  color: var(--foreground-muted);
  max-width: 720px;
  margin: 0 auto 2rem;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0.1s forwards;
}

.pricingInfo {
  font-size: 1.1rem;
  color: var(--foreground-muted);
  margin-bottom: 2.5rem;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0.2s forwards;
}

.ctaButtons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-top: 2.5rem;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0.3s forwards;
}

.socialProofSection {
  min-height: 100vh;
  padding: 6rem 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.8),
    transparent 15%,
    transparent 85%,
    rgba(0, 0, 0, 0.8)
  );
  overflow: hidden;
  position: relative;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.socialProofContent {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  position: relative;
}

.testimonialTrack {
  display: flex;
  gap: 2rem;
  animation: scrollLeft 40s linear infinite;
  margin: 4rem 0 6rem;
  width: fit-content;
  position: relative;
  z-index: 1;
  padding: 1rem;
}

.universitiesTrack {
  display: flex;
  gap: 4rem;
  animation: scrollRight 35s linear infinite;
  margin: 3rem 0;
  width: fit-content;
  align-items: center;
  padding: 1rem 0;
  position: relative;
  z-index: 1;
}

/* Add fade edges */
.socialProofSection::before,
.socialProofSection::after {
  content: "";
  position: absolute;
  top: 0;
  width: 20%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.socialProofSection::before {
  left: 0;
  background: linear-gradient(to right, var(--background), transparent);
}

.socialProofSection::after {
  right: 0;
  background: linear-gradient(to left, var(--background), transparent);
}

.universitiesSection {
  padding: 4rem 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.8),
    transparent 15%,
    transparent 85%,
    rgba(0, 0, 0, 0.8)
  );
  overflow: hidden;
  position: relative;
}

.universitiesContent {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.universitiesTitle {
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 3rem;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

.universitiesTrack {
  display: flex;
  gap: 4rem;
  animation: scrollRight 35s linear infinite;
  margin: 3rem 0;
  width: fit-content;
  align-items: center;
  padding: 1rem 0;
}

.universityName {
  font-size: 1.1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  transition: all 0.3s ease;
  flex-shrink: 0;
  cursor: default;
  letter-spacing: 0.02em;
}

.universityName:hover {
  color: var(--accent);
  transform: translateY(-2px);
}

@keyframes scrollRight {
  0% {
    transform: translateX(calc(-100% - 4rem));
  }
  100% {
    transform: translateX(0);
  }
}

.universitiesSlide {
  display: flex;
  gap: 4rem;
  padding: 0 2rem;
  animation: none;
  flex-shrink: 0;
  min-width: 100%;
}

.universitiesSlide span {
  font-size: 1.25rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  transition: all 0.3s ease;
  flex-shrink: 0;
}

.universitiesSlide span:hover {
  color: var(--accent);
  transform: translateY(-2px);
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

/* Add fade edges */
.socialProofSection::before,
.socialProofSection::after {
  content: "";
  position: absolute;
  top: 0;
  width: 20%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.socialProofSection::before {
  left: 0;
  background: linear-gradient(to right, var(--background), transparent);
}

.socialProofSection::after {
  right: 0;
  background: linear-gradient(to left, var(--background), transparent);
}

/* Add fade edges */
.universitiesSection::before,
.universitiesSection::after {
  content: "";
  position: absolute;
  top: 0;
  width: 20%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.universitiesSection::before {
  left: 0;
  background: linear-gradient(to right, var(--background), transparent);
}

.universitiesSection::after {
  right: 0;
  background: linear-gradient(to left, var(--background), transparent);
}

.pricingTiers {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin: 3rem 0;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0.2s forwards;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 3rem auto;
}

.pricingTier {
  position: relative;
  padding: 2rem;
  background: var(--background-light);
  border: 1px solid var(--border);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: var(--foreground);
  min-width: 220px;
}

.pricingTier:hover {
  border-color: var(--accent);
}

.highlighted {
  border-color: var(--accent);
}

.planAction {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.875rem 1.5rem;
  margin-top: 1.5rem;
  font-size: 0.9375rem;
  font-weight: 500;
  color: var(--foreground);
  background: transparent;
  border: 1px solid var(--border);
  transition: all 0.3s ease;
}

.pricingTier:hover .planAction {
  background: var(--accent);
  border-color: var(--accent);
  color: var(--background);
}

.highlighted .planAction {
  color: var(--foreground);
  border-color: var(--accent);
}

.highlighted:hover .planAction {
  background: var(--accent);
  color: var(--background);
}

.saveBadge {
  position: absolute;
  top: -12px;
  right: -12px;
  background: var(--accent);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: rotate(0deg);
  z-index: 1;
}

.tierName {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.tierPrice {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
  margin: 0.5rem 0;
  font-size: 1rem;
  color: var(--foreground-muted);
}

.price {
  font-size: 2.5rem;
  font-weight: 200;
  color: var(--foreground);
  line-height: 1;
}

.annualNote {
  font-size: 0.875rem;
  color: var(--foreground-muted);
  margin-top: 0.25rem;
}

.tierDescription {
  font-size: 0.95rem;
  color: var(--foreground-muted);
  text-align: center;
}

.ctaButtons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-top: 2.5rem;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0.3s forwards;
}

@media (max-width: 1024px) {
  .pricingTiers {
    gap: 1rem;
    padding: 0 1rem;
  }

  .pricingTier {
    min-width: 200px;
    padding: 1.5rem;
  }
}

.customPrice {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  color: var(--foreground-muted) !important;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

@media (max-width: 1200px) {
  .impactCard {
    min-width: 300px;
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .section,
  .impactSection {
    padding: 6rem var(--content-spacing);
  }

  .impactCard {
    min-width: 280px;
    max-width: 280px;
    padding: 2.5rem;
  }
}

.planAction {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.875rem 1.5rem;
  margin-top: 1.5rem;
  font-size: 0.9375rem;
  font-weight: 500;
  color: var(--foreground);
  background: transparent;
  border: 1px solid var(--border);
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  text-decoration: none;
}

.planAction:hover {
  background: var(--accent);
  border-color: var(--accent);
  color: var(--background);
}

.highlighted .planAction {
  color: var(--foreground);
  border-color: var(--accent);
}

.highlighted .planAction:hover {
  background: var(--accent);
  color: var(--background);
}

/* Base styles */
:root {
  --quiz-primary: var(--accent);
  --quiz-secondary: var(--accent-secondary);
  --quiz-hover: var(--background-hover);
  --animation-timing: 0.2s;
}

/* Scrollbar styles for preview content */
.previewContent {
  scrollbar-width: thin;
  scrollbar-color: var(--background) transparent;
}

.previewContent::-webkit-scrollbar {
  width: 6px;
}

.previewContent::-webkit-scrollbar-track {
  background: transparent;
}

.previewContent::-webkit-scrollbar-thumb {
  background-color: var(--background);
  border-radius: 20px;
}

.previewContent::-webkit-scrollbar-thumb:hover {
  background-color: var(--background-hover);
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 0;
  padding: 1rem 1.5rem;
  position: relative;
  flex-shrink: 0;
}

.tab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 1rem;
  background: transparent;
  color: var(--foreground-secondary);
  border: none;
  cursor: pointer;
  transition: all var(--animation-timing) ease-out;
  position: relative;
  font-weight: 500;
  z-index: 1;
}

.tab::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -1rem;
  width: 2rem;
  height: 2px;
  background: var(--border);
  transform: translateY(-50%);
}

.tab:first-child::before {
  display: none;
}

.tab::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2.5rem;
  height: 2.5rem;
  background: var(--background);
  border: 2px solid var(--border);
  border-radius: 50%;
  z-index: -1;
  transition: all var(--animation-timing) ease-out;
}

.tab svg {
  transition: transform var(--animation-timing) ease-out;
  position: relative;
  z-index: 1;
}

.tab span {
  font-size: 0.9rem;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0.8;
}

.tab.completed {
  color: var(--quiz-primary);
}

.tab.completed::before {
  background: var(--quiz-primary);
}

.tab.completed::after {
  border-color: var(--quiz-primary);
  background: var(--quiz-primary);
}

.tab.completed svg {
  color: white;
}

.tab.active {
  color: var(--quiz-primary);
}

.tab.active::after {
  border-color: var(--quiz-primary);
  transform: translate(-50%, -50%) scale(1.1);
}

.tab.active svg {
  transform: scale(1.1);
}

.tab.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.tab:hover:not(.active) {
  color: var(--foreground);
}

.content {
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.inputSection,
.settingsSection,
.previewSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 1.5rem;
  height: 100%;
  overflow: hidden;
}

.textInput {
  flex: 1;
  position: relative;
  background: var(--quiz-hover);
  border-radius: 12px;
  padding: 1rem;
  min-height: 200px;
  display: flex;
}

.textarea {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  color: var(--foreground);
  resize: none;
  font-size: 1rem;
  line-height: 1.6;
  font-family: inherit;
}

.textarea:focus {
  outline: none;
}

.textarea::placeholder {
  color: var(--foreground-secondary);
}

.fileUpload {
  border: 2px dashed var(--border);
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all var(--animation-timing) ease-out;
  background: var(--quiz-hover);
  flex-shrink: 0;
}

.fileUpload:hover {
  border-color: var(--quiz-primary);
}

.fileInput {
  display: none;
}

.fileLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  color: var(--foreground);
  cursor: pointer;
}

.fileLabel svg {
  color: var(--quiz-primary);
  transition: transform var(--animation-timing) ease-out;
}

.fileUpload:hover .fileLabel svg {
  transform: translateY(-2px);
}

.fileLabel small {
  color: var(--foreground-secondary);
  font-size: 0.875rem;
}

/* Settings Section */
.settingsSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  height: 100%;
  overflow: hidden;
}

.settingGroup {
  background: var(--quiz-hover);
  border-radius: 12px;
  padding: 1.5rem;
  transition: transform var(--animation-timing) ease-out;
  flex-shrink: 0;
}

.settingGroup:hover {
  transform: translateY(-1px);
}

.settingGroup h3 {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.25rem;
  color: var(--foreground);
  font-size: 1.1rem;
}

.settingGroup h3 svg {
  color: var(--quiz-primary);
}

.checkboxGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1rem;
}

.checkboxGrid label {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--foreground);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 6px;
  transition: background-color var(--animation-timing) ease-out;
}

.checkboxGrid label:hover {
  background: var(--background);
}

.checkboxGrid input[type="checkbox"] {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 4px;
  border: 2px solid var(--border);
  appearance: none;
  cursor: pointer;
  position: relative;
  transition: all var(--animation-timing) ease-out;
}

.checkboxGrid input[type="checkbox"]:checked {
  background: var(--quiz-primary);
  border-color: var(--quiz-primary);
}

.checkboxGrid input[type="checkbox"]:checked::after {
  content: '✓';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 0.8rem;
}

.settingControls {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

.settingControl {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.settingControl label {
  color: var(--foreground-secondary);
  font-size: 0.9rem;
  font-weight: 500;
}

.settingControl select,
.settingControl input {
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid var(--border);
  background: var(--background);
  color: var(--foreground);
  font-size: 1rem;
  transition: all var(--animation-timing) ease-out;
}

.settingControl select:hover,
.settingControl input:hover {
  border-color: var(--foreground-secondary);
}

.settingControl select:focus,
.settingControl input:focus {
  outline: none;
  border-color: var(--quiz-primary);
  box-shadow: 0 0 0 2px rgba(var(--quiz-primary-rgb), 0.1);
}

/* Remove additional features styles */
.additionalFeatures,
.additionalFeatures label,
.additionalFeatures input[type="checkbox"],
.additionalFeatures input[type="checkbox"]:checked,
.additionalFeatures input[type="checkbox"]:checked::after {
  display: none;
}

/* Preview Section */
.previewSection {
  padding: 1.5rem;
  height: 100%;
  overflow-y: auto;
}

.previewContent {
  background: var(--quiz-hover);
  border-radius: 12px;
  padding: 1.5rem;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--background) transparent;
}

.previewContent::-webkit-scrollbar {
  width: 6px;
}

.previewContent::-webkit-scrollbar-track {
  background: transparent;
}

.previewContent::-webkit-scrollbar-thumb {
  background-color: var(--background);
  border-radius: 20px;
}

.previewContent::-webkit-scrollbar-thumb:hover {
  background-color: var(--background-hover);
}

.previewContent h3 {
  margin-bottom: 1.5rem;
  color: var(--foreground);
  font-size: 1.25rem;
  text-align: center;
}

.previewGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  height: calc(100% - 4rem);
}

.previewItem {
  background: var(--background);
  padding: 1.25rem;
  border-radius: 12px;
  border: 1px solid var(--border);
  transition: transform var(--animation-timing) ease-out;
}

.previewItem:hover {
  transform: translateY(-1px);
}

.previewItem strong {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  color: var(--foreground);
  font-size: 1.1rem;
}

.previewItem strong::before {
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  background: var(--quiz-primary);
  border-radius: 50%;
}

.previewItem ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: var(--foreground-secondary);
}

.previewItem li {
  margin-bottom: 0.75rem;
  padding-left: 1rem;
  position: relative;
}

.previewItem li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: var(--quiz-primary);
}

.contentPreview {
  grid-column: 1 / -1;
}

.uploadedFile {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background: var(--background);
  border-radius: 8px;
  margin: 0.5rem 0;
  color: var(--foreground);
}

.contentText {
  margin-top: 0.5rem;
  padding: 1rem;
  background: var(--background);
  border-radius: 8px;
  color: var(--foreground);
  font-size: 0.9rem;
  line-height: 1.6;
  max-height: 200px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-break: break-word;
  scrollbar-width: thin;
  scrollbar-color: var(--background) transparent;
}

.contentText::-webkit-scrollbar {
  width: 6px;
}

.contentText::-webkit-scrollbar-track {
  background: transparent;
}

.contentText::-webkit-scrollbar-thumb {
  background-color: var(--background);
  border-radius: 20px;
}

.contentText::-webkit-scrollbar-thumb:hover {
  background-color: var(--background-hover);
}

/* Action Buttons */
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1.25rem 1.5rem;
  background: var(--background);
  border-top: 1px solid var(--border);
  flex-shrink: 0;
}

.button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all var(--animation-timing) ease-out;
  font-size: 1rem;
}

.button.primary {
  background: var(--quiz-primary);
  color: white;
  border: none;
}

.button.primary:hover {
  opacity: 0.9;
}

.button.secondary {
  background: var(--background);
  border: 1px solid var(--border);
  color: var(--foreground);
}

.button.secondary:hover {
  background: var(--quiz-hover);
}

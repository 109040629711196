@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* Dark theme (default) */
:root {
  --primary: #4f46e5;
  --primary-light: #818cf8;
  --secondary: #8b5cf6;
  --text-primary: #1e293b;
  --text-secondary: #475569;
  --bg-primary: #f8fafc;
  --bg-secondary: #f1f5f9;
  --card-bg: rgba(255, 255, 255, 0.8);
  --border: rgba(226, 232, 240, 0.8);
  --premium-gradient: linear-gradient(135deg, #4f46e5, #8b5cf6);
  --hover-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

@media (prefers-color-scheme: dark) {
  :root {
    --text-primary: #f1f5f9;
    --text-secondary: #cbd5e1;
    --bg-primary: #0f172a;
    --bg-secondary: #1e293b;
    --card-bg: rgba(30, 41, 59, 0.7);
    --border: rgba(51, 65, 85, 0.5);
    --hover-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-primary);
  color: var(--text-primary);
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 100vh;
  width: 100%;
}

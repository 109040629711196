:root {
  /* Dark theme (default) */
  --background: rgb(3, 7, 18);
  --background-light: rgba(255, 255, 255, 0.03);
  --background-lighter: rgba(255, 255, 255, 0.05);
  --foreground: rgb(255, 255, 255);
  --muted: rgba(255, 255, 255, 0.6);
  --border: rgba(255, 255, 255, 0.1);
  --accent: rgb(99, 102, 241);
  --accent-muted: rgba(99, 102, 241, 0.3);
  --success: rgb(34, 197, 94);
  --error: rgb(239, 68, 68);
  --content-spacing: 2rem;
  --header-bg: rgba(3, 7, 18, 0.85);
  --card-bg: rgba(255, 255, 255, 0.02);
  --card-border: rgba(255, 255, 255, 0.05);
  --hover-bg: rgba(255, 255, 255, 0.05);
  --search-bg: rgba(255, 255, 255, 0.03);
  --search-border: rgba(255, 255, 255, 0.05);
  --gradient-start: var(--foreground);
  --gradient-end: var(--muted);
}

/* Light theme */
[data-theme="light"] {
  --background: rgb(244, 245, 247);
  --background-light: rgb(255, 255, 255);
  --background-lighter: rgb(250, 251, 252);
  --foreground: rgb(30, 41, 59);
  --muted: rgb(71, 85, 105);
  --border: rgba(30, 41, 59, 0.1);
  --accent: rgb(79, 70, 229);
  --accent-muted: rgba(79, 70, 229, 0.15);
  --success: rgb(22, 163, 74);
  --error: rgb(220, 38, 38);
  --header-bg: rgba(255, 255, 255, 0.8);
  --card-bg: rgb(255, 255, 255);
  --card-border: rgba(30, 41, 59, 0.08);
  --hover-bg: rgba(79, 70, 229, 0.04);
  --search-bg: rgb(255, 255, 255);
  --search-border: rgba(30, 41, 59, 0.1);
  --gradient-start: var(--foreground);
  --gradient-end: var(--muted);
}

/* Transition for theme switching */
* {
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

/* Import Plus Jakarta Sans font */
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
}

.resourcesContainer {
  min-height: 100vh;
  background: var(--background);
  color: var(--foreground);
  position: relative;
  overflow: hidden;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 6rem var(--content-spacing);
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.backButton {
  position: fixed;
  top: 20px;
  left: 20px;
  padding: 10px 20px;
  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--foreground);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 99;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.backButton svg {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.backButton:hover {
  background: var(--background);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.backButton:hover svg {
  transform: translateX(-4px);
  color: var(--accent);
}

.backButton:hover span {
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* Adjust position when panel is open */
:global(body.panel-open.panel-left) .backButton {
  left: calc(50% + 20px);
}

.header {
  text-align: center;
  margin-bottom: 6rem;
}

.titleWrapper {
  margin-bottom: 2rem;
}

.title {
  font-size: clamp(2.5rem, 8vw, 4.5rem);
  font-weight: 200;
  line-height: 1.2;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 1rem;
}

.subtitle {
  font-size: clamp(1rem, 2vw, 1.25rem);
  color: var(--foreground-secondary);
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.typeGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.typeCard {
  position: relative;
  background: var(--card-bg);
  border: 1px solid var(--border);
  border-radius: 1rem;
  padding: 2rem;
  transition: all 0.3s ease;
}

.typeCard:hover {
  border-color: var(--accent);
  transform: translateY(-2px);
  box-shadow: var(--hover-shadow);
}

.typeNumber {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--accent);
}

.typeIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: var(--accent-light);
  border-radius: 1rem;
  color: var(--accent);
  margin-bottom: 1.5rem;
}

.typeTitle {
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--foreground);
  margin-bottom: 0.75rem;
}

.typeDescription {
  font-size: 0.875rem;
  color: var(--foreground-secondary);
  margin-bottom: 1rem;
  line-height: 1.6;
}

.typeMetric {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: var(--accent-light);
  color: var(--accent);
  border-radius: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.resourceSection {
  background: var(--card-bg);
  border: 1px solid var(--border);
  border-radius: 1rem;
  padding: 2rem;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.sectionTitle {
  font-size: 1.75rem;
  font-weight: 300;
  color: var(--foreground);
  margin-bottom: 2rem;
}

.sectionActions {
  display: flex;
  gap: 1rem;
}

.uploadButton, .createButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
}

.uploadButton {
  background: var(--accent);
  color: white;
  border: none;
}

.uploadButton:hover {
  background: var(--accent-dark);
}

.createButton {
  background: var(--accent-light);
  color: var(--accent);
  border: 1px solid var(--accent);
}

.createButton:hover {
  background: var(--accent);
  color: white;
}

.resourceControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.searchBar {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 0.5rem;
}

.searchBar input {
  flex: 1;
  background: none;
  border: none;
  color: var(--foreground);
  font-size: 0.875rem;
}

.searchBar input:focus {
  outline: none;
}

.viewControls {
  display: flex;
  gap: 0.5rem;
}

.viewButton, .filterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 0.5rem;
  color: var(--foreground-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.viewButton:hover, .viewButton.active,
.filterButton:hover {
  background: var(--accent-light);
  color: var(--accent);
  border-color: var(--accent);
}

.filters {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.filterChip {
  padding: 0.5rem 1rem;
  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 2rem;
  color: var(--foreground-secondary);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filterChip:hover, .filterChip.active {
  background: var(--accent-light);
  color: var(--accent);
  border-color: var(--accent);
}

.resourcesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.resourcesList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.resourceCard {
  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 1rem;
  transition: all 0.3s ease;
  opacity: 0;
  animation: slideUp 0.5s ease forwards;
}

.resourceCard:hover {
  transform: translateY(-2px);
  box-shadow: var(--hover-shadow);
  border-color: var(--accent);
}

.resourceContent {
  padding: 1.5rem;
}

.resourceContent h3 {
  font-size: 1rem;
  font-weight: 400;
  color: var(--foreground);
  margin-bottom: 0.5rem;
}

.resourceMeta {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
  color: var(--foreground-secondary);
}

.resourceTags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  padding: 0.25rem 0.75rem;
  background: var(--accent-light);
  color: var(--accent);
  border-radius: 1rem;
  font-size: 0.75rem;
}

.resourceActions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0.75rem;
  border-top: 1px solid var(--border);
}

.resourceActions button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: none;
  border: 1px solid var(--border);
  border-radius: 0.5rem;
  color: var(--foreground-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.resourceActions button:hover {
  background: var(--accent-light);
  color: var(--accent);
  border-color: var(--accent);
}

.resourceActionMenu {
  position: absolute;
  top: 100%;
  right: 0;
  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 160px;
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;
  transition: all 0.2s ease;
}

.resourceActionMenu.show {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.resourceActionItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  color: var(--foreground);
  cursor: pointer;
  transition: all 0.2s ease;
}

.resourceActionItem:hover {
  background: var(--accent-light);
  color: var(--accent);
}

.resourceActionItem.premium {
  color: var(--accent);
}

.resourceActionItem.premium:hover {
  background: var(--accent-light);
  color: var(--accent);
}

.renameWrapper {
  position: relative;
  width: 100%;
}

.renameInput {
  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  color: var(--foreground);
  width: 100%;
  margin: 0;
}

.renameInput:focus {
  outline: none;
  border-color: var(--accent);
  box-shadow: 0 0 0 2px var(--accent-light);
}

.uploadZone {
  border: 2px dashed var(--border);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 2rem;
}

.uploadZone:hover, .uploadZone.dragOver {
  border-color: var(--accent);
  background: var(--accent-light);
}

.uploadIcon {
  width: 48px;
  height: 48px;
  margin: 0 auto 1rem;
  color: var(--accent);
}

.uploadText {
  font-size: 1rem;
  color: var(--foreground);
  margin-bottom: 0.5rem;
}

.uploadSubtext {
  font-size: 0.875rem;
  color: var(--foreground-secondary);
}

.resourceListItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 0.75rem;
  transition: all 0.2s ease;
  opacity: 0;
  animation: slideUp 0.5s ease forwards;
}

.resourceListItem:hover {
  transform: translateY(-2px);
  box-shadow: var(--hover-shadow);
  border-color: var(--accent);
}

.resourceInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

.resourceIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: var(--accent-light);
  border-radius: 0.75rem;
  color: var(--accent);
}

.resourceDetails {
  flex: 1;
}

.resourceDetails h3 {
  font-size: 1rem;
  font-weight: 400;
  color: var(--foreground);
  margin-bottom: 0.25rem;
}

.resourceActionMenu {
  position: absolute;
  top: 100%;
  right: 0;
  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 0.75rem;
  padding: 0.5rem;
  min-width: 200px;
  box-shadow: var(--hover-shadow);
  z-index: 100;
  opacity: 0;
  transform: translateY(10px);
  pointer-events: none;
  transition: all 0.2s ease;
}

.resourceActionMenu.show {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.resourceActionItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  color: var(--foreground);
  font-size: 0.875rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.resourceActionItem:hover {
  background: var(--accent-light);
  color: var(--accent);
}

.resourceActionItem.premium {
  color: var(--accent);
}

.resourceActionItem.premium:hover {
  background: var(--accent);
  color: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .content {
    padding: 1rem;
  }

  .header {
    margin-bottom: 2rem;
  }

  .typeGrid {
    grid-template-columns: 1fr;
  }

  .sectionHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .resourceControls {
    flex-direction: column;
  }

  .searchBar {
    width: 100%;
  }

  .viewControls {
    width: 100%;
    justify-content: flex-end;
  }

  .resourcesGrid {
    grid-template-columns: 1fr;
  }

  .resourceListItem {
    flex-direction: column;
    align-items: flex-start;
  }

  .resourceActions {
    width: 100%;
    justify-content: flex-start;
  }
}

/* Global styles for main content adjustment */
:global(body) {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

:global(#root) {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

:global(body.panel-open) :global(#root) {
  width: 50%;
}

:global(body.panel-open.panel-right) :global(#root) {
  margin-right: 50%;
}

:global(body.panel-open.panel-left) :global(#root) {
  margin-left: 50%;
}

.sidePanel {
  position: fixed;
  top: 0;
  width: 50%;
  height: 100vh;
  background: var(--background);
  display: flex;
  flex-direction: column;
  z-index: 100;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.sidePanel.right {
  right: 0;
  border-left: 1px solid var(--border);
  transform: translateX(0);
}

.sidePanel.left {
  left: 0;
  border-right: 1px solid var(--border);
  transform: translateX(0);
}

.sidePanel.collapsed.right {
  transform: translateX(100%);
}

.sidePanel.collapsed.left {
  transform: translateX(-100%);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid var(--border);
  background: var(--background);
  height: 64px;
  gap: 1rem;
}

.headerControls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 36px;
}

.header h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--foreground);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  flex: 1;
}

.closeButton, .positionButton {
  background: none;
  border: 1px solid transparent;
  color: var(--foreground-secondary);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}

.closeButton:hover, .positionButton:hover {
  background: var(--accent-light);
  color: var(--accent);
  border-color: var(--accent);
  transform: scale(1.05);
}

.closeButton:active, .positionButton:active {
  transform: scale(0.95);
}

.collapseButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(var(--background-rgb), 0.6);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(var(--border-rgb), 0.3);
  width: 40px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--foreground-secondary);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.collapseButton::before {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--accent-light);
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.collapseIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.collapseIcon svg {
  width: 24px;
  height: 24px;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.sidePanel.right .collapseButton {
  left: -40px;
  border-radius: 8px 0 0 8px;
  border-right: none;
}

.sidePanel.left .collapseButton {
  right: -40px;
  border-radius: 0 8px 8px 0;
  border-left: none;
}

.collapseButton:hover {
  color: var(--accent);
  background: rgba(var(--background-rgb), 0.8);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

.collapseButton:hover::before {
  opacity: 0.1;
}

.collapseButton:active {
  transform: translateY(-50%) scale(0.98);
}

.content {
  flex: 1;
  overflow: hidden;
  background: var(--card-bg);
  position: relative;
}

.viewer {
  width: 100%;
  height: 100%;
  border: none;
  background: white;
}

/* Dots indicator */
.collapseIndicator {
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  z-index: 2;
}

.collapseIndicator span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: currentColor;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.collapseButton:hover .collapseIndicator span {
  background: var(--accent);
}

.collapsed .collapseText {
  opacity: 0;
}

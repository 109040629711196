.previewOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease;
}

.previewContent {
  background: var(--background);
  border-radius: 1rem;
  width: 90%;
  height: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  animation: slideUp 0.3s ease;
  overflow: hidden;
}

.previewHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid var(--border);
  background: var(--background);
}

.previewHeader h3 {
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--foreground);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.closeButton {
  background: none;
  border: none;
  color: var(--foreground-secondary);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  margin-left: 1rem;
  flex-shrink: 0;
}

.closeButton:hover {
  background: var(--accent-light);
  color: var(--accent);
}

.previewBody {
  flex: 1;
  overflow: hidden;
  background: var(--card-bg);
  position: relative;
}

.pdfViewer {
  width: 100%;
  height: 100%;
  border: none;
  background: white;
}

.textPreview {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 2rem;
  background: var(--background);
}

.textPreview pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: 'Courier New', monospace;
  font-size: 0.875rem;
  line-height: 1.6;
  color: var(--foreground);
}

.errorContainer,
.unsupported {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100%;
  padding: 2rem;
  text-align: center;
  color: var(--foreground-secondary);
}

.errorContainer svg,
.unsupported svg {
  color: var(--accent);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .previewContent {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .previewHeader {
    padding: 0.75rem;
  }

  .previewHeader h3 {
    font-size: 1rem;
  }

  .textPreview {
    padding: 1rem;
  }
}

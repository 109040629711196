@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.pricingContainer {
  min-height: 100vh;
  background: var(--background);
  color: var(--foreground);
  padding: 2rem;
  position: relative;
  overflow-x: hidden;
}

.content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 0;
}

.backButton {
  position: fixed;
  top: 2rem;
  left: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--foreground);
  background: transparent;
  border: none;
  z-index: 100;
  text-decoration: none;
}

.backButton svg {
  transition: transform 0.3s ease;
}

.backButton:hover svg {
  transform: translateX(-4px);
}

.header {
  text-align: center;
  margin-bottom: 6rem;
}

.titleWrapper {
  margin-bottom: 2rem;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

.title {
  font-size: clamp(3.5rem, 8vw, 6rem);
  font-weight: 200;
  line-height: 1.2;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: -0.02em;
  margin: 0;
  padding: 1rem 0;
}

.subtitle {
  font-size: 1.25rem;
  line-height: 1.6;
  color: var(--foreground-muted);
  max-width: 720px;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0.1s forwards;
}

.section {
  margin-bottom: 8rem;
}

.sectionTitle {
  font-size: 2.5rem;
  font-weight: 200;
  margin-bottom: 3rem;
  text-align: center;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: -0.02em;
  opacity: 0;
  transform: translateY(20px);
}

.plansGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.planCard {
  position: relative;
  padding: 2rem;
  background: var(--background-light);
  border: 1px solid var(--border);
  transition: all 0.3s ease;
  text-decoration: none;
  color: var(--foreground);
  opacity: 0;
  transform: translateY(20px);
}

.planCard:hover {
  border-color: var(--accent);
}

.planHeader {
  margin-bottom: 2rem;
}

.planName {
  font-size: 1.75rem;
  font-weight: 300;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.planPrice {
  font-size: 3rem;
  font-weight: 200;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.price {
  color: var(--foreground);
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
}

.period {
  font-size: 1rem;
  color: var(--foreground-muted);
}

.annualNote {
  text-align: center;
  margin: 0.5rem 0 1rem;
  font-size: 0.875rem;
  color: var(--accent);
  font-weight: 500;
}

.planCard:last-child .planPrice {
  font-size: 2.5rem;
}

.planCard:last-child .period {
  font-size: 1.1rem;
  margin-top: 0.25rem;
  color: var(--accent);
  font-weight: 500;
}

.planDescription {
  color: var(--foreground-muted);
  line-height: 1.6;
}

.planFeatures {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem;
  flex-grow: 1;
}

.planFeatures li {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  color: var(--foreground-muted);
}

.planFeatures li svg {
  color: var(--accent);
  flex-shrink: 0;
}

.planAction {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.875rem 1.5rem;
  margin-top: 1.5rem;
  font-size: 0.9375rem;
  font-weight: 500;
  color: var(--foreground);
  background: transparent;
  border: 1px solid var(--border);
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.planCard:hover .planAction {
  background: var(--accent);
  border-color: var(--accent);
  color: var(--background);
}

.highlighted .planAction {
  color: var(--foreground);
  border-color: var(--accent);
}

.highlighted:hover .planAction {
  background: var(--accent);
  color: var(--background);
}

.planCard.highlighted {
  border-color: var(--accent);
  background: linear-gradient(to bottom, var(--card-bg), var(--background));
  position: relative;
  overflow: visible;
}

.planCard.highlighted::before {
  content: "SAVE 30%";
  position: absolute;
  top: -12px;
  right: -12px;
  background: var(--accent);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
  transform: rotate(0deg);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.featuresGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.featureCard {
  padding: 2rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 2px;
  opacity: 0;
  transform: translateY(20px);
  transition: transform 0.4s ease, border-color 0.4s ease;
}

.featureCard:hover {
  transform: translateY(-8px);
  border-color: var(--accent);
}

.featureIcon {
  color: var(--accent);
  margin-bottom: 1.5rem;
}

.featureTitle {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.featureDescription {
  color: var(--foreground-muted);
  line-height: 1.6;
}

.faqGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.faqCard {
  padding: 2rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 2px;
  opacity: 0;
  transform: translateY(20px);
  transition: transform 0.4s ease, border-color 0.4s ease;
}

.faqCard:hover {
  transform: translateY(-8px);
  border-color: var(--accent);
}

.faqIcon {
  color: var(--accent);
  margin-bottom: 1.5rem;
}

.faqQuestion {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.faqAnswer {
  color: var(--foreground-muted);
  line-height: 1.6;
}

.universitiesSection {
  background: transparent;
  overflow: visible;
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: 100% !important;
}

.universitiesSection .sectionTitle {
  padding-left: var(--content-spacing);
  padding-right: var(--content-spacing);
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.universitiesCarouselContainer {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  overflow: hidden;
  position: relative;
  background: var(--background);
}

.universitiesCarousel {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  width: max-content;
  animation: uniScroll 60s linear infinite;
  will-change: transform;
}

.universitiesCarousel:hover {
  animation-play-state: paused;
}

.universityItem {
  padding: 2rem 3rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 2px;
  text-align: center;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  min-width: 200px;
}

.universityItem h3 {
  font-size: 1.25rem;
  font-weight: 300;
  color: var(--foreground);
  margin: 0;
  white-space: nowrap;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.universityItem:hover {
  transform: translateY(-4px);
  border-color: var(--accent);
}

.universityItem::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, var(--accent-muted) 0%, transparent 100%);
  opacity: 0;
  transition: opacity 0.4s ease;
}

.universityItem:hover::before {
  opacity: 0.05;
}

.universitiesSection::before,
.universitiesSection::after {
  content: "";
  position: absolute;
  top: 0;
  width: 15%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.universitiesSection::before {
  left: 0;
  background: linear-gradient(to right, var(--background), transparent);
}

.universitiesSection::after {
  right: 0;
  background: linear-gradient(to left, var(--background), transparent);
}

@media (max-width: 768px) {
  .universityItem {
    padding: 1.5rem 2rem;
    min-width: 160px;
  }

  .universityItem h3 {
    font-size: 1.125rem;
  }
}

@media (max-width: 1200px) {
  .universitiesGrid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .universitiesGrid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
  }

  .universityItem {
    padding: 1.5rem;
  }

  .universityItem h3 {
    font-size: 1.125rem;
  }
}

@media (max-width: 768px) {
  .plansGrid,
  .featuresGrid,
  .faqGrid {
    grid-template-columns: 1fr;
  }

  .title {
    font-size: clamp(2.5rem, 6vw, 4rem);
  }

  .universitiesGrid {
    margin: 2rem auto;
  }
}

@keyframes clipReveal {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transform: translateY(0);
    opacity: 1;
  }
}

.universitiesGrid .universityItem:nth-child(1) {
  --index: 1;
}
.universitiesGrid .universityItem:nth-child(2) {
  --index: 2;
}
.universitiesGrid .universityItem:nth-child(3) {
  --index: 3;
}
.universitiesGrid .universityItem:nth-child(4) {
  --index: 4;
}
.universitiesGrid .universityItem:nth-child(5) {
  --index: 5;
}
.universitiesGrid .universityItem:nth-child(6) {
  --index: 6;
}
.universitiesGrid .universityItem:nth-child(7) {
  --index: 7;
}
.universitiesGrid .universityItem:nth-child(8) {
  --index: 8;
}
.universitiesGrid .universityItem:nth-child(9) {
  --index: 9;
}
.universitiesGrid .universityItem:nth-child(10) {
  --index: 10;
}
.universitiesGrid .universityItem:nth-child(11) {
  --index: 11;
}
.universitiesGrid .universityItem:nth-child(12) {
  --index: 12;
}
.universitiesGrid .universityItem:nth-child(13) {
  --index: 13;
}
.universitiesGrid .universityItem:nth-child(14) {
  --index: 14;
}
.universitiesGrid .universityItem:nth-child(15) {
  --index: 15;
}
.universitiesGrid .universityItem:nth-child(16) {
  --index: 16;
}
.universitiesGrid .universityItem:nth-child(17) {
  --index: 17;
}
.universitiesGrid .universityItem:nth-child(18) {
  --index: 18;
}
.universitiesGrid .universityItem:nth-child(19) {
  --index: 19;
}
.universitiesGrid .universityItem:nth-child(20) {
  --index: 20;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.inView {
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

@keyframes uniScroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-50% - 1rem));
  }
}

[data-animate] {
  animation: fadeInUp 0.6s ease forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

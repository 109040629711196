.container {
  max-width: 1200px;
  margin: 0 auto;
  background: #0f172a;
  border-radius: 24px;
  border: 1px solid rgba(148, 163, 184, 0.1);
  display: flex;
  flex-direction: column;
  height: 700px;
  overflow: hidden;
}

.demoContainer {
  max-width: 1000px;
  margin: 0 auto;
  background: #0f172a;
  border-radius: 24px;
  border: 1px solid rgba(148, 163, 184, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chatHeader {
  padding: 16px 24px;
  background: rgba(30, 41, 59, 0.7);
  border-bottom: 1px solid rgba(148, 163, 184, 0.1);
  backdrop-filter: blur(8px);
  z-index: 10;
}

.assistantInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.assistantAvatar {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.avatarInner {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #3b82f6, #6366f1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.25rem;
  box-shadow: 0 2px 8px rgba(59, 130, 246, 0.2);
}

.assistantStatus h3 {
  margin: 0;
  font-size: 1rem;
  color: #f8fafc;
  font-weight: 600;
}

.assistantStatus span {
  color: #94a3b8;
  font-size: 0.875rem;
}

.assistantName {
  font-weight: 600;
  font-size: 1.125rem;
  background: linear-gradient(135deg, #f0f9ff 0%, #60a5fa 50%, #3b82f6 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.chatArea {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.1) transparent;
  background: #0f172a;
  scroll-behavior: smooth;
  align-items: flex-start;
}

.chatArea::-webkit-scrollbar {
  width: 6px;
}

.chatArea::-webkit-scrollbar-track {
  background: transparent;
}

.chatArea::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.messagesContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: min-content;
}

.userMessage {
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;
  max-width: 100%;
}

.assistantMessage {
  display: flex;
  justify-content: flex-start;
  margin: 8px 0;
  max-width: 85%;
}

.assistantMessageContent {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  max-width: 100%;
}

.messageAvatar {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.messageAvatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.messageBubble {
  background: rgba(99, 102, 241, 0.15);
  border: 1px solid rgba(99, 102, 241, 0.2);
  color: #f8fafc;
  padding: 16px;
  border-radius: 16px;
  border-bottom-right-radius: 4px;
  max-width: 85%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.messageContent {
  white-space: pre-wrap;
  line-height: 1.6;
  font-size: 0.9375rem;
  overflow-wrap: break-word;
  word-break: break-word;
  position: relative;
}

.messageContent h2,
.messageContent h3,
.messageContent .codeBlock,
.messageContent table,
.messageContent p {
  background: rgba(30, 41, 59, 0.5);
  border: 1px solid rgba(148, 163, 184, 0.1);
  border-radius: 12px;
  padding: 16px;
  margin: 12px 0;
  backdrop-filter: blur(8px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(148, 163, 184, 0.05);
  transition: all 0.2s ease;
}

.messageContent h2 {
  margin: 0;
  font-size: 1.25rem;
  color: #60a5fa;
  font-weight: 600;
}

.messageContent h3 {
  margin: 12px 0 6px;
  font-size: 1.125rem;
  color: #93c5fd;
}

.messageContent .codeBlock {
  font-family: "SF Mono", "Consolas", monospace;
  font-size: 0.875rem;
  color: #93c5fd;
  line-height: 1.5;
}

.messageContent ul {
  margin: 8px 0;
  padding-left: 24px;
}

.messageContent li {
  margin: 4px 0;
  position: relative;
}

.messageContent li::before {
  content: "•";
  color: #60a5fa;
  position: absolute;
  left: -18px;
}

.messageContent code {
  background: rgba(30, 41, 59, 0.5);
  padding: 2px 6px;
  border-radius: 4px;
  font-family: "SF Mono", "Consolas", monospace;
  font-size: 0.875rem;
  color: #93c5fd;
}

.messageContent pre {
  background: rgba(30, 41, 59, 0.5);
  padding: 16px;
  border-radius: 8px;
  margin: 12px 0;
  border: 1px solid rgba(148, 163, 184, 0.1);
  overflow-x: auto;
}

.messageContent pre code {
  background: none;
  padding: 0;
  color: #e2e8f0;
}

.messageContent table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.messageContent th {
  background: rgba(59, 130, 246, 0.1);
  color: #60a5fa;
  font-weight: 600;
  text-align: left;
  padding: 12px 16px;
}

.messageContent td {
  padding: 12px 16px;
  border-top: 1px solid rgba(148, 163, 184, 0.1);
}

.messageContent tr:hover {
  background: rgba(59, 130, 246, 0.05);
}

.messageContent blockquote {
  margin: 12px 0;
  padding: 8px 16px;
  border-left: 3px solid #60a5fa;
  background: rgba(30, 41, 59, 0.3);
  font-style: italic;
}

.messageContent a {
  color: #60a5fa;
  text-decoration: none;
  border-bottom: 1px dashed #60a5fa;
}

.messageContent a:hover {
  border-bottom-style: solid;
}

.assistantMessage .messageContent {
  color: #f1f5f9;
  padding: 16px;
  border-radius: 12px;
  background: rgba(59, 130, 246, 0.05);
  max-width: 100%;
}

/* Responsive */
@media (max-width: 768px) {
  .demoContainer {
    border-radius: 0;
    height: 100vh;
  }

  .chatArea {
    padding: 16px;
  }

  .messageBubble {
    max-width: 90%;
  }
}

.typingText {
  position: relative;
  display: inline-block;
}

.cursor {
  display: inline-block;
  margin-left: 2px;
  opacity: 1;
  color: #60a5fa;
  animation: blink 1s step-end infinite;
  font-weight: 200;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.optionsContainer {
  display: grid;
  gap: 0.75rem;
  margin-top: 0.75rem;
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
  animation-delay: 0.2s;
}

.option {
  background: rgba(30, 41, 59, 0.4);
  border: 1px solid rgba(148, 163, 184, 0.1);
  border-radius: 16px;
  padding: 1rem 1.25rem;
  color: #f8fafc;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.option::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(59, 130, 246, 0.1) 50%,
    transparent 50%,
    transparent 50%,
    rgba(59, 130, 246, 0.1) 50%,
    rgba(59, 130, 246, 0.1) 75%,
    transparent 75%
  );
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.option:hover::before {
  transform: translateX(100%);
}

.option:hover {
  background: rgba(30, 41, 59, 0.6);
  border-color: rgba(59, 130, 246, 0.2);
  transform: translateY(-1px);
}

.option:active {
  transform: translateY(0);
}

.optionIcon {
  font-size: 1.25rem;
  min-width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(59, 130, 246, 0.1);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.option:hover .optionIcon {
  background: rgba(59, 130, 246, 0.15);
  transform: scale(1.05);
}

.optionContent {
  flex: 1;
}

.optionContent h4 {
  margin: 0;
  font-size: 0.9375rem;
  font-weight: 600;
  color: #f8fafc;
}

.optionContent p {
  margin: 0.25rem 0 0;
  font-size: 0.875rem;
  color: #94a3b8;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 24px;
  background: rgba(30, 41, 59, 0.7);
  border-bottom: 1px solid rgba(148, 163, 184, 0.1);
  backdrop-filter: blur(8px);
}

.headerLogo {
  display: flex;
  align-items: center;
}

.headerLogo img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  overflow: hidden;
}

.table th,
.table td {
  padding: 12px;
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.table th {
  background: rgba(255, 255, 255, 0.1);
  font-weight: 600;
}

.table tr:hover {
  background: rgba(255, 255, 255, 0.05);
}

.codeBlock {
  background: rgba(0, 0, 0, 0.3);
  padding: 1rem;
  border-radius: 8px;
  font-family: "Fira Code", monospace;
  margin: 1rem 0;
  position: relative;
}

.codeBlock::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.05) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.05) 50%,
    rgba(255, 255, 255, 0.05) 75%,
    transparent 75%
  );
  background-size: 4px 4px;
  pointer-events: none;
  opacity: 0.1;
}

.graphContainer {
  width: 100%;
  height: 240px;
  background: rgba(15, 23, 42, 0.6);
  border-radius: 12px;
  padding: 1.5rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.graphContainer::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.03) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.03) 50%,
    rgba(255, 255, 255, 0.03) 75%,
    transparent 75%
  );
  background-size: 8px 8px;
  pointer-events: none;
  opacity: 0.5;
}

.graphContainer svg {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

.graphContainer text {
  font-family: "Fira Code", monospace;
  font-size: 12px;
  fill: rgba(255, 255, 255, 0.8);
}

.graphContainer line {
  stroke-linecap: round;
  transition: stroke-opacity 0.2s ease;
}

.graphContainer path {
  filter: drop-shadow(0 1px 2px rgba(96, 165, 250, 0.3));
}

.graphContainer circle {
  filter: drop-shadow(0 1px 2px rgba(245, 158, 11, 0.3));
}

.explanation {
  color: #94a3b8;
  font-size: 0.95rem;
  line-height: 1.6;
  margin: 1rem 0;
  padding: 0 1rem;
}

.infoBox {
  background: rgba(15, 23, 42, 0.4);
  border: 1px solid rgba(96, 165, 250, 0.2);
  border-radius: 12px;
  padding: 1rem;
  margin: 1rem 0;
}

.infoBox h4 {
  color: #60a5fa;
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
}

.infoBox ul {
  margin: 0;
  padding-left: 1.5rem;
  color: #94a3b8;
  font-size: 0.9rem;
  line-height: 1.6;
}

.infoBox li {
  margin: 0.25rem 0;
}

.blockquote {
  border-left: 4px solid rgba(255, 255, 255, 0.2);
  padding-left: 1rem;
  margin: 1rem 0;
  font-style: italic;
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem;
  border-radius: 0 8px 8px 0;
}

.chemicalReaction {
  font-family: "Fira Code", monospace;
  text-align: center;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 1rem 0;
}

.arrow {
  color: rgba(255, 255, 255, 0.5);
  margin: 0 0.5rem;
}

.molecule {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin: 0 0.25rem;
}

.physicsSection {
  background: rgba(15, 23, 42, 0.4);
  border-radius: 16px;
  padding: 1.5rem;
  margin: 1rem 0;
  border: 1px solid rgba(96, 165, 250, 0.1);
}

.physicsSection h2 {
  color: #60a5fa;
  font-size: 1.5rem;
  margin: 0 0 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.physicsSection h2::before {
  content: "📐";
  font-size: 1.2em;
}

.physicsSection h3 {
  color: #94a3b8;
  font-size: 1.2rem;
  margin: 1.5rem 0 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.physicsSection h3::before {
  content: "📊";
  font-size: 1.2em;
}

.explanation {
  color: #94a3b8;
  font-size: 0.95rem;
  line-height: 1.6;
  margin: 0.75rem 0;
  padding: 0.75rem 1rem;
  background: rgba(30, 41, 59, 0.4);
  border-radius: 8px;
  border-left: 3px solid #60a5fa;
}

.graphContainer {
  width: 100%;
  height: 280px;
  background: rgba(15, 23, 42, 0.8);
  border-radius: 12px;
  padding: 2rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3),
    0 1px 2px rgba(96, 165, 250, 0.1);
  border: 1px solid rgba(96, 165, 250, 0.1);
}

.graphContainer::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    rgba(96, 165, 250, 0.03) 25%,
    transparent 25%,
    transparent 50%,
    rgba(96, 165, 250, 0.03) 50%,
    rgba(96, 165, 250, 0.03) 75%,
    transparent 75%
  );
  background-size: 16px 16px;
  pointer-events: none;
  opacity: 0.5;
  animation: gradientMove 30s linear infinite;
}

@keyframes gradientMove {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100px 100px;
  }
}

.graphContainer svg {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  max-height: 100%;
}

.graphContainer text {
  font-family: "Fira Code", monospace;
  font-size: 12px;
  fill: rgba(255, 255, 255, 0.8);
  transition: fill 0.2s ease;
}

.graphContainer text:hover {
  fill: #60a5fa;
}

.graphContainer line {
  stroke-linecap: round;
  transition: all 0.2s ease;
}

.graphContainer line:hover {
  stroke-width: 2px;
  stroke: rgba(255, 255, 255, 0.3);
}

.graphContainer path {
  filter: drop-shadow(0 1px 2px rgba(96, 165, 250, 0.3));
}

.graphContainer circle {
  filter: drop-shadow(0 1px 2px rgba(245, 158, 11, 0.3));
  transition: r 0.2s ease;
}

.graphContainer circle:hover {
  r: 8;
}

.infoBox {
  background: rgba(30, 41, 59, 0.4);
  border: 1px solid rgba(96, 165, 250, 0.2);
  border-radius: 12px;
  padding: 1.25rem;
  margin: 1.5rem 0;
  position: relative;
  overflow: hidden;
}

.infoBox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #60a5fa, #34d399, #60a5fa);
  animation: gradientSlide 3s linear infinite;
}

@keyframes gradientSlide {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.infoBox h4 {
  color: #60a5fa;
  margin: 0 0 1rem 0;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.infoBox h4::before {
  content: "💡";
  font-size: 1.2em;
}

.infoBox ul {
  margin: 0;
  padding-left: 1.5rem;
  color: #94a3b8;
  font-size: 0.95rem;
  line-height: 1.7;
  list-style-type: none;
}

.infoBox li {
  margin: 0.5rem 0;
  position: relative;
  padding-left: 1rem;
}

.infoBox li::before {
  content: "•";
  color: #60a5fa;
  position: absolute;
  left: -1rem;
  top: 0;
}

.legend {
  display: flex;
  gap: 1.5rem;
  padding: 0.75rem 1rem;
  background: rgba(30, 41, 59, 0.4);
  border-radius: 8px;
  margin: 1rem 0;
  flex-wrap: wrap;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #94a3b8;
  font-size: 0.9rem;
}

.legendColor {
  width: 12px;
  height: 12px;
  border-radius: 3px;
}

/* Binary Search Demo Styles */
.demoContainer {
  padding: 2rem;
  background-color: rgba(15, 23, 42, 0.6);
  border-radius: 12px;
  border: 1px solid rgba(147, 197, 253, 0.2);
}

.sectionTitle {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  color: rgba(147, 197, 253, 0.9);
}

.sectionTitle h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.icon {
  font-size: 1.5rem;
}

.codeBlockContainer {
  margin: 2rem 0;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(30, 41, 59, 0.8);
}

.codeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: rgba(51, 65, 85, 0.8);
  border-bottom: 1px solid rgba(147, 197, 253, 0.2);
}

.copyButton {
  background: none;
  border: 1px solid rgba(147, 197, 253, 0.3);
  color: rgba(147, 197, 253, 0.8);
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.copyButton:hover {
  background-color: rgba(147, 197, 253, 0.1);
}

.tableContainer {
  margin: 2rem 0;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(147, 197, 253, 0.2);
}

.complexityTable {
  width: 100%;
  border-collapse: collapse;
  background-color: rgba(30, 41, 59, 0.8);
}

.complexityTable th,
.complexityTable td {
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid rgba(147, 197, 253, 0.2);
}

.complexityTable th {
  background-color: rgba(51, 65, 85, 0.8);
  color: rgba(147, 197, 253, 0.9);
  font-weight: 600;
}

.insightBox {
  margin: 2rem 0;
  padding: 1.5rem;
  background: linear-gradient(
    135deg,
    rgba(147, 197, 253, 0.1),
    rgba(52, 211, 153, 0.1)
  );
  border-radius: 8px;
  border: 1px solid rgba(147, 197, 253, 0.2);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.insightIcon {
  font-size: 1.5rem;
}

.applicationsContainer {
  margin: 2rem 0;
  padding: 1.5rem;
  background-color: rgba(30, 41, 59, 0.8);
  border-radius: 8px;
  border: 1px solid rgba(147, 197, 253, 0.2);
}

.applicationsContainer h3 {
  color: rgba(147, 197, 253, 0.9);
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.applicationsList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.applicationsList li {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid rgba(147, 197, 253, 0.1);
}

.applicationsList li:last-child {
  border-bottom: none;
}

.appIcon {
  font-size: 1.25rem;
}

.insight {
  margin: 20px 0;
  padding: 15px;
  background: linear-gradient(
    135deg,
    rgba(147, 197, 253, 0.1),
    rgba(52, 211, 153, 0.1)
  );
  border-radius: 8px;
  text-align: center;
  border: 1px solid rgba(147, 197, 253, 0.2);
}

.insight span {
  color: rgba(147, 197, 253, 0.9);
  font-weight: 500;
}

.applications {
  margin-top: 20px;
}

.applications .insight {
  margin: 0 0 15px 0;
  padding: 12px;
}

.applications ul {
  list-style: none;
  padding: 0 16px;
  margin: 0;
}

.applications li {
  padding: 8px 0;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 8px;
}

.applications li:before {
  content: "•";
  color: rgba(147, 197, 253, 0.9);
  margin-right: 8px;
}

.codeBlock {
  background-color: rgba(15, 23, 42, 0.6);
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  font-family: "Fira Code", monospace;
  font-size: 14px;
  line-height: 1.5;
}

.keyword {
  color: #60a5fa; /* Light blue for keywords */
}

.function {
  color: #34d399; /* Light green for function names */
}

.comment {
  color: #94a3b8; /* Light gray for comments */
  font-style: italic;
}

.operator {
  color: #f472b6; /* Pink for operators */
}

.number {
  color: #fbbf24; /* Yellow for numbers */
}

.string {
  color: #a78bfa; /* Purple for strings */
}

/*! ---------------------------------------- Contact Page Styles ---------------------------------------- */

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.contactContainer {
  min-height: 100vh;
  background: var(--background);
  color: var(--foreground);
  padding: 2rem;
  position: relative;
  overflow-x: hidden;
}

.content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 0;
}

.backButton {
  position: fixed;
  top: 2rem;
  left: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--foreground);
  background: transparent;
  border: none;
  z-index: 100;
  text-decoration: none;
}

.backButton svg {
  transition: transform 0.3s ease;
}

.backButton:hover svg {
  transform: translateX(-4px);
}

.header {
  text-align: center;
  margin-bottom: 6rem;
}

.titleWrapper {
  margin-bottom: 2rem;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

.title {
  font-size: clamp(3.5rem, 8vw, 6rem);
  font-weight: 200;
  line-height: 1.2;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: -0.02em;
  margin: 0;
  padding: 1rem 0;
}

.subtitle {
  font-size: 1.25rem;
  line-height: 1.6;
  color: var(--foreground-muted);
  max-width: 720px;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0.1s forwards;
}

.section {
  margin-bottom: 8rem;
}

.sectionTitle {
  font-size: 2.5rem;
  font-weight: 200;
  margin-bottom: 3rem;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: -0.02em;
  opacity: 0;
  transform: translateY(20px);
}

.methodGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.methodCard {
  padding: 3rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 2px;
  opacity: 0;
  transform: translateY(20px);
  transition: transform 0.4s ease, border-color 0.4s ease;
}

.methodCard:hover {
  transform: translateY(-8px);
  border-color: var(--accent);
}

.methodIcon {
  color: var(--accent);
  margin-bottom: 1.5rem;
}

.methodTitle {
  font-size: 1.75rem;
  font-weight: 300;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.methodDescription {
  color: var(--foreground-muted);
  line-height: 1.6;
  margin-bottom: 2rem;
}

.methodAction {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--accent);
  text-decoration: none;
  font-weight: 500;
  transition: gap 0.3s ease;
}

.methodAction:hover {
  gap: 1rem;
}

.socialGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.socialCard {
  padding: 3rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 2px;
  text-decoration: none;
  color: var(--foreground);
  opacity: 0;
  transform: translateY(20px);
  transition: transform 0.4s ease, border-color 0.4s ease;
}

.socialCard:hover {
  transform: translateY(-8px);
  border-color: var(--accent);
}

.socialIcon {
  color: var(--accent);
  margin-bottom: 1.5rem;
}

.socialPlatform {
  font-size: 1.75rem;
  font-weight: 300;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.socialDescription {
  color: var(--foreground-muted);
  line-height: 1.6;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.inView {
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

.container {
  min-height: 100vh;
  background: var(--background);
  color: var(--foreground);
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem var(--content-spacing);
}

.backButton {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--foreground);
  text-decoration: none;
  margin-bottom: 3rem;
  position: relative;
  padding-bottom: 0.25rem;
}

.backButton::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--accent);
  transition: width 0.3s ease;
}

.backButton:hover::after {
  width: 100%;
}

.header {
  margin-bottom: 5rem;
  position: relative;
}

.header::after {
  content: "";
  position: absolute;
  bottom: -2rem;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, var(--accent), transparent);
}

.title {
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 600;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  opacity: 0;
  transform: translateY(20px);
}

.subtitle {
  font-size: 1.25rem;
  color: var(--foreground-muted);
  max-width: 800px;
  line-height: 1.6;
  opacity: 0;
  transform: translateY(20px);
}

.section {
  margin-bottom: 6rem;
  opacity: 0;
  transform: translateY(20px);
  position: relative;
}

.section::before {
  content: "";
  position: absolute;
  top: -2rem;
  left: 0;
  width: 3rem;
  height: 3px;
  background: var(--accent);
  border-radius: 2px;
}

.sectionTitle {
  font-size: 2rem;
  margin-bottom: 3rem;
  color: var(--foreground);
  position: relative;
  display: inline-block;
}

.sectionTitle::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--accent);
  transition: width 0.3s ease;
}

.section:hover .sectionTitle::after {
  width: 100%;
}

.featureGrid,
.cycleGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

.featureCard,
.cycleCard {
  padding: 2.5rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.featureCard::before,
.cycleCard::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, var(--accent-muted) 0%, transparent 100%);
  opacity: 0;
  transition: opacity 0.4s ease;
}

.featureCard:hover,
.cycleCard:hover {
  transform: translateY(-4px);
  border-color: var(--accent);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.featureCard:hover::before,
.cycleCard:hover::before {
  opacity: 0.05;
}

.featureIcon,
.cycleIcon {
  color: var(--accent);
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  position: relative;
}

.featureIcon::after,
.cycleIcon::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 2rem;
  height: 2px;
  background: var(--accent);
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: left;
}

.featureCard:hover .featureIcon::after,
.cycleCard:hover .cycleIcon::after {
  transform: scaleX(1);
}

.featureCard h3,
.cycleCard h3 {
  font-size: 1.35rem;
  margin-bottom: 1rem;
  color: var(--foreground);
  position: relative;
  display: inline-block;
}

.featureCard p,
.cycleCard p {
  color: var(--foreground-muted);
  line-height: 1.7;
  font-size: 1.05rem;
}

.featureCard a,
.cycleCard a,
.timelineItem a,
.methodCard a {
  color: var(--accent);
  text-decoration: none;
  position: relative;
  padding-bottom: 0.25rem;
}

.featureCard a::after,
.cycleCard a::after,
.timelineItem a::after,
.methodCard a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--accent);
  transition: width 0.3s ease;
}

.featureCard a:hover::after,
.cycleCard a:hover::after,
.timelineItem a:hover::after,
.methodCard a:hover::after {
  width: 100%;
}

.timeline {
  display: grid;
  gap: 2rem;
}

.timelineItem {
  padding: 2rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 2px;
  position: relative;
}

.timelineItem::before {
  content: "";
  position: absolute;
  left: -1rem;
  top: 50%;
  width: 2rem;
  height: 2px;
  background: var(--accent);
  transform: translateY(-50%);
}

.timelineItem h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: var(--foreground);
}

.timelineItem p {
  color: var(--foreground-muted);
  line-height: 1.6;
}

.methodGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2.5rem;
  margin-top: 2rem;
}

.methodCard {
  padding: 2.5rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.methodCard:hover {
  transform: translateY(-4px);
  border-color: var(--accent);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.methodList {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
}

.methodList li {
  padding: 0.75rem 0;
  color: var(--foreground-muted);
  position: relative;
  padding-left: 1.5rem;
  line-height: 1.6;
}

.methodList li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 6px;
  height: 6px;
  background: var(--accent);
  border-radius: 50%;
  transform: translateY(-50%);
}

.studyGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2.5rem;
  margin-top: 2rem;
}

.studyCard {
  padding: 2.5rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.studyCard:hover {
  transform: translateY(-4px);
  border-color: var(--accent);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.studyList {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
}

.studyList li {
  padding: 0.75rem 0;
  color: var(--foreground-muted);
  position: relative;
  padding-left: 1.5rem;
  line-height: 1.6;
}

.studyList li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 6px;
  height: 6px;
  background: var(--accent);
  border-radius: 50%;
  transform: translateY(-50%);
}

.researchStats {
  display: flex;
  gap: 2rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--card-border);
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.stat strong {
  font-size: 1.5rem;
  color: var(--accent);
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.stat span {
  font-size: 0.875rem;
  color: var(--foreground-muted);
}

.studyMetrics {
  display: flex;
  gap: 2rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--card-border);
}

.metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.metric strong {
  font-size: 1.5rem;
  color: var(--accent);
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.metric span {
  font-size: 0.875rem;
  color: var(--foreground-muted);
}

.architectureGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.architectureCard {
  padding: 2.5rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.architectureCard:hover {
  transform: translateY(-4px);
  border-color: var(--accent);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.techList {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0 0;
}

.techList li {
  padding: 0.75rem 0;
  color: var(--foreground-muted);
  position: relative;
  padding-left: 1.5rem;
  line-height: 1.6;
}

.techList li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 6px;
  height: 6px;
  background: var(--accent);
  border-radius: 50%;
  transform: translateY(-50%);
}

.metricsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.metricCard {
  padding: 2rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 4px;
  text-align: center;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.metricCard:hover {
  transform: translateY(-4px);
  border-color: var(--accent);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.metricCard h3 {
  font-size: 2.5rem;
  color: var(--accent);
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.metricCard p {
  font-size: 1.1rem;
  color: var(--foreground);
  margin-bottom: 0.5rem;
}

.metricCard span {
  font-size: 0.9rem;
  color: var(--foreground-muted);
}

.studyList,
.updateList {
  display: grid;
  gap: 1.5rem;
}

.studyItem,
.updateItem {
  padding: 2rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 2px;
  display: grid;
  gap: 1rem;
}

.studyItem h3,
.updateItem h3 {
  font-size: 1.25rem;
  color: var(--foreground);
}

.studyItem p,
.updateItem p {
  color: var(--foreground-muted);
  line-height: 1.6;
}

.date {
  color: var(--accent);
  font-size: 0.875rem;
}

.inView {
  animation: slideUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.systemGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2.5rem;
  margin-top: 2rem;
}

.systemCard {
  padding: 2.5rem;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.systemCard:hover {
  transform: translateY(-4px);
  border-color: var(--accent);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.systemList {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
}

.systemList li {
  padding: 1rem 0;
  color: var(--foreground-muted);
  position: relative;
  line-height: 1.6;
}

.systemList li strong {
  color: var(--foreground);
  display: block;
  margin-bottom: 0.25rem;
}

@media (max-width: 768px) {
  .content {
    padding: 1rem var(--content-spacing);
  }

  .header {
    margin-bottom: 3rem;
  }

  .section {
    margin-bottom: 4rem;
  }

  .featureGrid,
  .cycleGrid {
    grid-template-columns: 1fr;
  }

  .timelineItem::before {
    display: none;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap");

.container {
  min-height: 100vh;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  color: var(--foreground);
  position: relative;
  overflow-x: hidden;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.navbar {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.75rem 1.5rem;
  border-radius: 16px;
  width: 90%;
  max-width: 1200px;
}

.navContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logoIcon {
  height: 32px;
  width: auto;
}

.navLinks {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.navLink {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 0.95rem;
  font-weight: 500;
  transition: color 0.2s ease;
}

.navLink:hover {
  color: rgba(255, 255, 255, 0.9);
}

.loginButton {
  color: #818cf8;
  background: rgba(129, 140, 248, 0.1);
  padding: 0.5rem 1.25rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.95rem;
  transition: all 0.2s ease;
}

.loginButton:hover {
  background: rgba(129, 140, 248, 0.15);
}

.heroSection {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 2rem;
  text-align: center;
}

.heroContent {
  max-width: 800px;
  margin: 0 auto;
}

.glooPlus {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 1.5rem;
}

.bramloText {
  font-size: 2rem;
  font-weight: 600;
  background: linear-gradient(135deg, #fff 0%, #818cf8 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.plusSign {
  font-size: 2rem;
  font-weight: 600;
  color: #818cf8;
}

.heroTitle {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #fff 0%, rgba(255, 255, 255, 0.7) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.heroDescription {
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.pricingInfo {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 2rem;
}

.ctaButtons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.primaryButton {
  background: #818cf8;
  color: #fff;
  padding: 0.875rem 1.75rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.95rem;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.2s ease;
}

.primaryButton:hover {
  background: #6b7af7;
}

.secondaryButton {
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
  padding: 0.875rem 1.75rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.95rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

.secondaryButton:hover {
  background: rgba(255, 255, 255, 0.08);
}

.featuresSection {
  padding: 4rem 2rem;
  background: rgba(255, 255, 255, 0.02);
}

.spotlightEffect {
  display: none;
}

.header {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background: rgba(var(--background-rgb), 0.4);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.75rem;
  border-radius: 16px;
  width: 90%;
  max-width: 1100px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 0 1rem;
}

.logo {
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
  background: linear-gradient(135deg, #fff 0%, #a78bfa 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.logoIcon {
  height: 2rem;
  width: auto;
  object-fit: contain;
  filter: brightness(1.05);
  margin-right: -0.1rem;
}

.searchContainer {
  position: relative;
  flex: 1;
  max-width: 500px;
}

.searchInput {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.75rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: var(--foreground);
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.searchInput:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.05);
}

.searchIcon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--muted);
}

.userControls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.iconButton {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--muted);
  padding: 0.625rem;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconButton:hover {
  background: rgba(255, 255, 255, 0.06);
  border-color: rgba(255, 255, 255, 0.2);
  color: var(--foreground);
}

.iconButton:active {
  transform: translateY(0);
}

.notificationBadge {
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  color: white;
  font-size: 0.75rem;
  padding: 0.125rem 0.375rem;
  border-radius: 10px;
  transform: translate(25%, -25%);
  font-weight: 500;
}

.mainContent {
  padding-top: 7rem;
  max-width: 1400px;
  margin: 0 auto;
  padding-inline: var(--content-spacing);
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 3.5rem;
  position: relative;
}

.pageHeader::after {
  content: "";
  position: absolute;
  bottom: -1.75rem;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(99, 102, 241, 0.2),
    transparent
  );
}

.welcomeTitle {
  font-size: 2.5rem;
  font-weight: 200;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #fff 0%, #a78bfa 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.welcomeSubtitle {
  color: var(--muted);
  font-size: 1.125rem;
  line-height: 1.6;
}

.quickStats {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(12px);
}

.statItem {
  text-align: center;
  position: relative;
  padding: 0 1.5rem;
}

.statItem:not(:last-child)::after {
  content: "";
  position: absolute;
  right: -1.25rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 70%;
  background: linear-gradient(
    180deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
}

.statValue {
  display: block;
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--foreground);
  margin-bottom: 0.375rem;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--muted) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.statLabel {
  color: var(--muted);
  font-size: 0.875rem;
  font-weight: 500;
}

.contentGrid {
  display: grid;
  grid-template-columns: 1fr 350px;
  gap: 2.5rem;
  margin-top: 2rem;
  contain: content;
}

.mainSection {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2rem;
  position: relative;
}

.sectionTitle {
  font-size: 1.375rem;
  font-weight: 600;
  color: var(--foreground);
  letter-spacing: -0.5px;
}

.sectionSubtitle {
  color: var(--muted);
  font-size: 0.875rem;
  margin-top: 0.5rem;
  line-height: 1.6;
}

.featuresGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 1.5rem;
  contain: content;
  padding: 0.5rem;
  margin: -0.5rem;
}

.featureCard {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 2rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(12px);
}

.featureCard:hover {
  background: rgba(255, 255, 255, 0.03);
  border-color: rgba(99, 102, 241, 0.3);
  transform: translateY(-2px);
}

.premiumFeature {
  background: linear-gradient(
    to bottom right,
    var(--background-light),
    rgba(99, 102, 241, 0.05)
  );
}

.premiumBadge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  border-radius: 9999px;
  color: white;
  font-size: 0.75rem;
  font-weight: 500;
}

.featureIcon {
  display: inline-flex;
  padding: 1rem;
  background: rgba(99, 102, 241, 0.1);
  border-radius: 12px;
  color: #818cf8;
  margin-bottom: 1.5rem;
  position: relative;
  font-size: 1.5rem;
}

.featureTitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  background: linear-gradient(135deg, #fff 0%, #a78bfa 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.featureOverlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
}

.spinner {
  width: 2rem;
  height: 2rem;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-top-color: #818cf8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.errorMessage {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  padding: 0.75rem;
  background: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.2);
  border-radius: 8px;
  color: rgb(239, 68, 68);
  font-size: 0.875rem;
  text-align: center;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.featureContent {
  margin-bottom: 1.5rem;
}

.featureDescription {
  color: var(--muted);
  font-size: 0.875rem;
  line-height: 1.6;
}

.featureMetrics {
  display: flex;
  gap: 1.5rem;
  padding-top: 1.25rem;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.metricItem {
  flex: 1;
}

.metricValue {
  display: block;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--accent);
  margin-bottom: 0.25rem;
}

.metricLabel {
  color: var(--muted);
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.sidebar {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1.75rem;
  backdrop-filter: blur(10px);
}

.viewAllButton {
  background: none;
  border: none;
  color: var(--accent);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  border-radius: 6px;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.viewAllButton:hover {
  background: rgba(99, 102, 241, 0.1);
  border-color: rgba(99, 102, 241, 0.2);
}

.activityList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
}

.activityItem {
  display: flex;
  gap: 1rem;
  padding: 1.25rem;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.activityItem:hover {
  transform: translateX(4px);
  border-color: rgba(99, 102, 241, 0.2);
  background: rgba(255, 255, 255, 0.03);
}

.activityIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  background: rgba(99, 102, 241, 0.1);
  border-radius: 10px;
  color: var(--accent);
  position: relative;
  overflow: hidden;
}

.activityIcon::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transform: rotate(45deg);
  animation: shimmer 3s infinite;
}

.activityContent {
  flex: 1;
}

.activityHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.activityTitle {
  font-size: 0.9375rem;
  font-weight: 500;
  color: var(--foreground);
  letter-spacing: -0.3px;
}

.activityTime {
  font-size: 0.75rem;
  color: var(--muted);
  font-weight: 500;
}

.activityDetail {
  font-size: 0.8125rem;
  color: var(--muted);
  line-height: 1.5;
}

.performanceWidget {
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.widgetTitle {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: var(--foreground);
  letter-spacing: -0.3px;
}

.performanceStats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.performanceStat {
  padding: 1.25rem;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  text-align: center;
  transition: all 0.3s ease;
}

.performanceStat:hover {
  background: rgba(255, 255, 255, 0.03);
  border-color: rgba(99, 102, 241, 0.2);
  transform: translateY(-2px);
}

.statTrend {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 0.5rem;
  letter-spacing: 0.5px;
}

.positive {
  background: rgba(34, 197, 94, 0.1);
  color: rgb(34, 197, 94);
  border: 1px solid rgba(34, 197, 94, 0.2);
}

.negative {
  background: rgba(239, 68, 68, 0.1);
  color: rgb(239, 68, 68);
  border: 1px solid rgba(239, 68, 68, 0.2);
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.textInput {
  position: relative;
}

.textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border);
  border-radius: 8px;
  background: var(--background-light);
  color: var(--text-primary);
  font-size: 0.875rem;
  resize: none;
  transition: all 0.2s ease;
}

.textarea:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 2px var(--primary-transparent);
}

.fileUpload {
  margin-top: 1rem;
}

.fileInput {
  display: none;
}

.fileLabel {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: rgba(99, 102, 241, 0.1);
  border: 1px solid rgba(99, 102, 241, 0.2);
  border-radius: 8px;
  color: #818cf8;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.fileLabel:hover {
  background: rgba(99, 102, 241, 0.15);
  border-color: rgba(99, 102, 241, 0.3);
}

.loadingIndicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.spinner {
  width: 2rem;
  height: 2rem;
  border: 2px solid rgba(99, 102, 241, 0.1);
  border-top-color: #818cf8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.inputOptions {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.inputOption {
  background: var(--background);
  border-radius: 8px;
  padding: 1.5rem;
}

.inputTitle {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: var(--foreground);
}

.divider {
  position: relative;
  text-align: center;
  margin: 1rem 0;
}

.divider::before,
.divider::after {
  content: "";
  position: absolute;
  top: 50%;
  width: calc(50% - 2rem);
  height: 1px;
  background: var(--border);
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.divider span {
  background: var(--background-light);
  padding: 0.5rem 1rem;
  color: var(--foreground-muted);
  font-size: 0.875rem;
}

.fileInput {
  display: none;
}

.fileLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  border: 2px dashed var(--border);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.fileLabel:hover {
  border-color: var(--accent);
  background: rgba(99, 102, 241, 0.05);
}

.fileLabel svg {
  color: var(--foreground-muted);
}

.fileLabel span {
  color: var(--foreground);
}

.fileLabel .supportedFormats {
  font-size: 0.75rem;
  color: var(--foreground-muted);
}

.optionsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 0.5rem;
}

.optionItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.optionItem label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border);
  border-radius: 4px;
  background: var(--background);
  color: var(--foreground);
  font-size: 0.875rem;
}

.select:focus {
  outline: none;
  border-color: var(--accent);
}

.modalForm {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.label {
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
}

.select {
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.select:focus {
  outline: none;
  border-color: rgba(99, 102, 241, 0.5);
  background: rgba(255, 255, 255, 0.05);
}

.chatInput {
  position: relative;
}

.textarea {
  width: 100%;
  min-height: 100px;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  line-height: 1.5;
  resize: vertical;
  transition: all 0.2s ease;
}

.textarea:focus {
  outline: none;
  border-color: rgba(99, 102, 241, 0.5);
  background: rgba(255, 255, 255, 0.05);
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.primaryButton {
  background: rgba(99, 102, 241, 0.1);
  border: 1px solid rgba(99, 102, 241, 0.2);
  color: #818cf8;
}

.primaryButton:hover:not(:disabled) {
  background: rgba(99, 102, 241, 0.15);
  border-color: rgba(99, 102, 241, 0.3);
}

@media (max-width: 1200px) {
  .contentGrid {
    grid-template-columns: 1fr;
  }

  .sidebar {
    order: -1;
  }

  .quickStats {
    padding: 1.25rem;
    gap: 2rem;
  }

  .statItem {
    padding: 0 1rem;
  }
}

@media (max-width: 768px) {
  .headerContent {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
  }

  .userControls {
    margin-left: 0;
    justify-content: flex-end;
  }

  .searchContainer {
    max-width: 100%;
  }

  .pageHeader {
    flex-direction: column;
    gap: 2rem;
  }

  .quickStats {
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    gap: 1.5rem;
  }

  .statItem {
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .statItem:last-child {
    border-bottom: none;
  }

  .statItem::after {
    display: none;
  }

  .featuresGrid {
    grid-template-columns: 1fr;
  }

  .welcomeTitle {
    font-size: 1.75rem;
  }
}

/* Content Viewer */
.contentViewer {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 500px;
  height: 600px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(12px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.contentViewer.fullscreen {
  inset: 1rem;
  width: auto;
  height: auto;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.contentHeader h3 {
  font-size: 1.125rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
}

.contentControls {
  display: flex;
  gap: 0.5rem;
}

.contentControls button {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  padding: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentControls button:hover {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
}

.contentBody {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
}

/* Flashcards */
.flashcardContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.flashcard {
  flex: 1;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  cursor: pointer;
}

.flashcard.flipped {
  transform: rotateY(180deg);
}

.flashcardFront,
.flashcardBack {
  position: absolute;
  inset: 0;
  padding: 2rem;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.flashcardBack {
  transform: rotateY(180deg);
  background: rgba(99, 102, 241, 0.1);
}

.flashcardControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
}

.flashcardControls button {
  padding: 0.75rem 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.flashcardControls button:hover:not(:disabled) {
  background: rgba(255, 255, 255, 0.1);
}

.flashcardControls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Quiz Container and Layout */
.quizContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px); /* Adjust height to fit modal */
  padding: 1.5rem;
  gap: 1rem;
}

.quizHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.questionProgress {
  display: flex;
  gap: 0.75rem;
}

.progressDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
}

.questionNumber {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
}

.questionCard {
  flex: 1;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-height: 400px; /* Ensure minimum height */
}

.questionCard h3 {
  font-size: 1.25rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.4;
}

.options {
  display: grid;
  gap: 1rem;
}

.option {
  width: 100%;
  text-align: left;
  padding: 1rem 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.optionKey {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  min-width: 24px;
}

.explanation {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1.25rem;
}

.explanation h4 {
  font-size: 0.875rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.75rem;
}

.explanation p {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
}

.quizFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.navigationButton,
.submitButton {
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.navigationButton {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
}

.submitButton {
  background: rgba(99, 102, 241, 0.1);
  border: 1px solid rgba(99, 102, 241, 0.2);
  color: #818cf8;
}

/* Content Input Styles */
.contentInput {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
}

.inputToggle {
  display: flex;
  gap: 0.75rem;
  padding: 0.25rem;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.toggleButton {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border: none;
  background: transparent;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.875rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.toggleButton:hover {
  color: rgba(255, 255, 255, 0.9);
  background: rgba(255, 255, 255, 0.03);
}

.toggleButton.active {
  background: rgba(99, 102, 241, 0.1);
  color: #818cf8;
}

.textarea {
  width: 100%;
  min-height: 150px;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  line-height: 1.6;
  resize: vertical;
  transition: all 0.2s ease;
}

.textarea:focus {
  outline: none;
  border-color: rgba(99, 102, 241, 0.3);
  background: rgba(255, 255, 255, 0.03);
}

.fileUploadArea {
  min-height: 150px;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  transition: all 0.2s ease;
}

.fileUploadArea:hover {
  border-color: rgba(99, 102, 241, 0.3);
  background: rgba(99, 102, 241, 0.05);
}

.fileInput {
  display: none;
}

.fileLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  text-align: center;
}

.fileLabel svg {
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.5);
}

.supportedFormats {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}

.selectedFile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.fileInfo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.fileInfo svg {
  color: rgba(255, 255, 255, 0.5);
}

.fileSize {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}

.removeFile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.2);
  border-radius: 8px;
  color: rgb(239, 68, 68);
  cursor: pointer;
  transition: all 0.2s ease;
}

.removeFile:hover {
  background: rgba(239, 68, 68, 0.15);
  border-color: rgba(239, 68, 68, 0.3);
}

/* Quiz Options */
.quizOptions {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.quizOptionLabel {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
}

.questionCounter {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  width: fit-content;
}

.counterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.counterButton:hover:not(:disabled) {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}

.counterButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.premiumHint {
  font-size: 0.75rem;
  color: #818cf8;
  font-style: italic;
}

.submitSection {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.submitButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: rgba(99, 102, 241, 0.1);
  border: 1px solid rgba(99, 102, 241, 0.2);
  border-radius: 10px;
  color: #818cf8;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submitButton:hover:not(:disabled) {
  background: rgba(99, 102, 241, 0.15);
  border-color: rgba(99, 102, 241, 0.3);
}

.submitButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: var(--background);
  border-radius: 16px;
  width: 90%;
  max-width: 900px;
  height: 90vh;
  max-height: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: modalEnter 0.3s ease;
  border: 1px solid var(--border);
}

.header {
  padding: 1.25rem;
  border-bottom: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--foreground);
  margin: 0;
}

.close {
  background: transparent;
  border: none;
  color: var(--foreground-secondary);
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.close:hover {
  background: var(--background-hover);
  color: var(--foreground);
}

.content {
  flex: 1;
  overflow: hidden;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

.premium {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: var(--accent);
  background: var(--accent-transparent);
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
}

.premiumContent {
  text-align: center;
  padding: 3rem 2rem;
  color: var(--foreground);
}

.premiumContent h3 {
  margin: 1rem 0;
  font-size: 1.5rem;
}

.premiumContent p {
  color: var(--foreground-secondary);
  margin-bottom: 2rem;
}

.upgrade {
  background: linear-gradient(135deg, #ff6b6b 0%, #ffd93d 100%);
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.upgrade:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(255, 107, 107, 0.2);
}

/* Add padding-right to prevent content shift when modal opens */
.backdrop::before {
  content: '';
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* This matches the width of the scrollbar to prevent shifting */
  padding-right: calc(100vw - 100%);
  pointer-events: none;
}

.optionsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  padding: 16px 0;
}

.optionCard {
  background: var(--background-light);
  border: 1px solid var(--border);
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  overflow: hidden;
}

.optionCard::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(45deg, var(--primary-transparent), transparent);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.optionCard:hover {
  transform: translateY(-2px);
  border-color: var(--primary);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.optionCard:hover::before {
  opacity: 0.1;
}

.optionIcon {
  width: 40px;
  height: 40px;
  background: var(--primary-transparent);
  border-radius: 10px;
  display: grid;
  place-items: center;
  color: var(--primary);
}

.optionTitle {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.optionDescription {
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0;
  line-height: 1.5;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid var(--border);
}

.button {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.buttonSecondary {
  background: var(--background);
  border: 1px solid var(--border);
  color: var(--text-primary);
}

.buttonSecondary:hover {
  background: var(--background-light);
  border-color: var(--text-secondary);
}

.buttonPrimary {
  background: var(--primary);
  border: none;
  color: white;
}

.buttonPrimary:hover {
  opacity: 0.9;
}

@keyframes modalEnter {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 640px) {
  .modal {
    max-height: 90vh;
  }
  
  .content {
    max-height: calc(90vh - 65px);
  }
}

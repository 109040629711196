@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
}

.authContainer {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: var(--background);
  position: relative;
}

.content {
  width: 100%;
  max-width: 480px;
}

.backButton {
  position: fixed;
  top: 2rem;
  left: 2rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--foreground-muted);
  text-decoration: none;
  font-size: 0.875rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  transition: color 0.3s ease;
  z-index: 10;
}

.backButton:hover {
  color: var(--foreground);
}

.backButton svg {
  transition: transform 0.3s ease;
}

.backButton:hover svg {
  transform: translateX(-4px);
}

.formContainer {
  background: var(--background-light);
  padding: 2.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.titleWrapper {
  text-align: center;
  margin-bottom: 2.5rem;
  animation: fadeInDown 0.6s ease forwards;
}

.title {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 2.5rem;
  font-weight: 200;
  color: var(--foreground);
  margin-bottom: 1.25rem;
  line-height: 1.2;
  background: linear-gradient(135deg, var(--foreground) 0%, var(--accent) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  letter-spacing: -0.02em;
}

.subtitle {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: var(--foreground-muted);
  opacity: 0.8;
  letter-spacing: -0.02em;
}

.socialButtons {
  margin-bottom: 2rem;
  animation: fadeInUp 0.6s ease forwards;
  animation-delay: 0.1s;
  opacity: 0;
}

.socialButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border);
  border-radius: 2px;
  background: transparent;
  color: var(--foreground);
  font-size: 0.875rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 300;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.socialButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(64, 224, 208, 0.05),
    rgba(0, 206, 209, 0.1),
    transparent
  );
  transition: all 0.6s ease;
}

.socialButton:hover {
  border-color: rgba(64, 224, 208, 0.3);
  background: linear-gradient(
    135deg,
    rgba(64, 224, 208, 0.05) 0%,
    rgba(0, 206, 209, 0.05) 100%
  );
  box-shadow: 0 2px 8px rgba(64, 224, 208, 0.1);
}

.socialButton:hover::before {
  left: 100%;
}

.googleIcon {
  width: 18px;
  height: 18px;
  color: var(--foreground);
  transition: color 0.3s ease;
}

.socialButton:hover .googleIcon {
  color: rgb(64, 224, 208);
}

.socialButton span {
  position: relative;
  z-index: 1;
}

.divider {
  position: relative;
  text-align: center;
  margin: 2rem 0;
  animation: fadeIn 0.6s ease forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.divider::before,
.divider::after {
  content: "";
  position: absolute;
  top: 50%;
  width: calc(50% - 1rem);
  height: 1px;
  background: var(--border);
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.divider span {
  background: var(--background-light);
  padding: 0 1rem;
  color: var(--foreground-muted);
  font-size: 0.875rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 300;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.formFields {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  animation: fadeInUp 0.6s ease forwards;
  animation-delay: 0.3s;
  opacity: 0;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-size: 0.875rem;
  font-weight: 300;
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: var(--foreground);
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.inputIcon {
  position: absolute;
  left: 1rem;
  color: var(--foreground-muted);
}

.input {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border: 1px solid var(--border);
  border-radius: 2px;
  background: transparent;
  color: var(--foreground);
  font-size: 0.875rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 300;
  transition: all 0.3s ease;
}

.input:focus {
  outline: none;
  border-color: var(--accent);
  box-shadow: 0 0 0 2px var(--accent-light);
}

.input::placeholder {
  color: var(--foreground-muted);
}

.forgotPassword {
  text-align: right;
}

.link {
  color: var(--accent);
  text-decoration: none;
  font-size: 0.875rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 300;
  transition: all 0.3s ease;
}

.link:hover {
  text-decoration: underline;
}

.submitButton {
  width: 100%;
  padding: 1rem;
  background: var(--accent);
  color: white;
  border: none;
  border-radius: 2px;
  font-size: 0.875rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: fadeInUp 0.6s ease forwards;
  animation-delay: 0.4s;
  opacity: 0;
}

.submitButton:hover {
  background: var(--accent-dark);
  transform: translateY(-2px);
}

.switchForm {
  text-align: center;
  margin-top: 1.5rem;
  animation: fadeInUp 0.6s ease forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

.switchForm p {
  color: var(--foreground-muted);
  font-size: 0.875rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 300;
}

.switchButton {
  background: none;
  border: none;
  color: var(--accent);
  font-size: 0.875rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 300;
  cursor: pointer;
  padding: 0;
  margin-left: 0.5rem;
  transition: color 0.3s ease;
}

.switchButton:hover {
  color: var(--accent-dark);
  text-decoration: underline;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 640px) {
  .formContainer {
    padding: 2rem;
  }

  .title {
    font-size: 2rem;
  }

  .divider::before,
  .divider::after {
    width: calc(50% - 4rem);
  }
}
